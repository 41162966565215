<div class="selection-container">
  <h6 class="odx-title-6" i18n="@@localeSelection.header1">Land auswählen</h6>
  <p class="odx-text--small" i18n="@@localeSelection.subtitle1">
    Es werden die für dieses Land verfügbaren Artikel und die festgelegten Preise angezeigt.
  </p>
  <button
    *ngFor="let country of COUNTRIES"
    (click)="onSelect(country.countryCode)"
    [ngClass]="selectedCountry === country.countryCode ? 'selected' : ''"
  >
    {{ country.displayName }}
  </button>
</div>
