import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-delete-button",
  templateUrl: "./delete-button.component.html",
  styleUrl: "./delete-button.component.scss",
  standalone: true,
  imports: [UiKitModule],
})
export class DeleteButtonComponent {
  @Input({ required: true }) disabled!: boolean;
  @Output() delete = new EventEmitter();

  protected onClickDelete() {
    this.delete.emit();
  }
}
