import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataUrlMapperService {
  fileToDataURL(file: Blob): Observable<string> {
    return new Observable<string>((observer: any) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        observer.next(reader.result);
        observer.complete();
      };
      reader.onerror = () => {
        observer.error();
        observer.complete();
      };
      reader.readAsDataURL(file);
    });
  }
}
