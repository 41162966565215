<table class="navinta-table" *ngIf="productData || placeholderData">
  <thead>
    <td class="col-10" i18n="@@productList.productTable.column1.header" data-testid="productList.productTable.column1">Pos.</td>
    <td class="col-10" i18n="@@productList.productTable.column2.header" data-testid="productList.productTable.column2">Anzahl</td>
    <td class="col-30" i18n="@@productList.productTable.column3.header" data-testid="productList.productTable.column3">
      Bezeichnung
    </td>
    <td class="col-20" i18n="@@productList.productTable.column4.header" data-testid="productList.productTable.column4">
      Sachnummer
    </td>
    <td class="col-15" i18n="@@productList.productTable.column5.header" data-testid="productList.productTable.column5">
      Einzelpreis
    </td>
    <td class="col-15" i18n="@@productList.productTable.column6.header" data-testid="productList.productTable.column6">
      Gesamtpreis
    </td>
  </thead>
  <tbody *ngFor="let data of productData">
    <tr *ngFor="let product of data" [ngClass]="!product.isAvailable ? 'discontinued' : ''">
      <td>
        {{ product.position + (product.isAvailable ? "" : " ")
        }}<img *ngIf="!product.isAvailable" src="assets/warning-colored-odx.svg" alt="warning" />
      </td>
      <td>{{ product.productPlacements }}</td>
      <td>{{ product.name }}</td>
      <td>{{ product.id }}</td>
      <td>
        {{ !showCosts ? "" : (product.productCosts | currency: "EUR") }}
      </td>
      <td>
        {{ !showCosts ? "" : (product.totalProductCost | currency: "EUR") }}
      </td>
    </tr>
  </tbody>
  <tbody *ngFor="let data of placeholderData">
    <tr *ngFor="let product of data" [ngClass]="{ 'text-red': product.position!.startsWith('P') }">
      <td>{{ product.position }}</td>
      <td>{{ product.productPlacements }}</td>
      <td>{{ product.name }}</td>
      <td>{{ product.id || "" }}</td>
      <td></td>
      <td></td>
    </tr>
  </tbody>
</table>
