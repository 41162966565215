<app-floorplan-menu-item-buttons>
  <app-add-button (add)="addMeasurementLine()"></app-add-button>
  <app-toggle-visibility-button
    [visible]="floorplanState.measurementLinesVisible"
    (toggle)="floorplanState.measurementLinesVisible = $event"
  >
  </app-toggle-visibility-button>
  <app-toggle-lock-button
    [locked]="floorplanState.measurementLinesLocked"
    (toggle)="floorplanState.measurementLinesLocked = $event"
  >
  </app-toggle-lock-button>
</app-floorplan-menu-item-buttons>
