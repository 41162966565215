import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { AttachmentSelectorComponent } from "@components/attachment-selector/attachment-selector.component";
import { ConfigurationWizardBackButtonComponent } from "@components/configuration-wizard-back-button/configuration-wizard-back-button.component";
import { ProductConfigStepperBarComponent } from "@components/stepper/product-config-stepper-bar/product-config-stepper-bar.component";
import { Product } from "@domain/product/product";
import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";
import { Project } from "@domain/project/project";
import { WizardComponent } from "@odx/angular/components/wizard";
import { AlarmDeviceSelectorComponent } from "@project/alarm-device-configuration/alarm-device-selector/alarm-device-selector.component";
import { SafetyClass, VoltageClass } from "@project/alarm-device-configuration/device-class";
import { DeviceClassSelectorComponent } from "@project/alarm-device-configuration/device-class-selector/device-class-selector.component";
import { OriginalAlarmDeviceConfiguration } from "@project/alarm-device-configuration/original-alarm-device-configuration";
import { FloorplanMenuType } from "@project/floorplanner/floorplan-menu/floorplan-menu-type";
import { OriginalProductConfigurationFactory } from "@project/original-product-configuration-factory";

@Component({
  selector: "app-alarm-device-configuration",
  templateUrl: "./alarm-device-configuration.component.html",
  styleUrls: ["./alarm-device-configuration.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    ReactiveFormsModule,
    DeviceClassSelectorComponent,
    AlarmDeviceSelectorComponent,
    AttachmentSelectorComponent,
    ProductConfigStepperBarComponent,
    ConfigurationWizardBackButtonComponent,
  ],
  providers: [OriginalProductConfigurationFactory],
})
export class AlarmDeviceConfigurationComponent implements OnInit {
  protected readonly FloorplanMenuType = FloorplanMenuType;
  protected readonly SafetyClass = SafetyClass;
  protected readonly VoltageClass = VoltageClass;

  protected project!: Project;
  protected selectedAlarmDevices?: Product[];
  protected selectedAttachments: Product[] = [];
  protected selectedSafetyClass?: SafetyClass;
  protected selectedVoltageClass?: VoltageClass;
  protected deviceClassSelectorForm = this.formBuilder.nonNullable.group({});
  protected deviceSelectorForm?: FormGroup;
  protected showCosts = false;
  protected skipAttachments = true;
  protected originalConfiguration?: OriginalAlarmDeviceConfiguration;
  protected isOriginalAlarmDevicesSelected = false;
  protected activeStep = 0;
  protected steps = 2;

  protected titleSelectSafetyClass: string = $localize`:@@alarmDeviceConfiguration.selectSafetyClass.header:Schutzart auswählen`;
  protected titleSelectVoltageClass: string = $localize`:@@alarmDeviceConfiguration.selectVoltageClass.header:Spannungsversorgung auswählen`;

  @ViewChild("configurationWizard")
  private configurationWizard!: WizardComponent;

  @ViewChild("backButton")
  private backButton!: ConfigurationWizardBackButtonComponent;

  constructor(
    private productConfigurationService: OriginalProductConfigurationFactory,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.project = this.activeRoute.parent!.snapshot.data["project"];
    this.showCosts = this.project.showCostFlag;
    if (this.activeRoute.snapshot.queryParams["config_id"]) {
      this.productConfigurationService
        .createOriginalAlarmDeviceConfiguration(this.project, this.activeRoute.snapshot.queryParams["config_id"])
        .subscribe((originalConfiguration) => {
          this.originalConfiguration = originalConfiguration;
          this.updateIsOriginalAlarmDevicesSelected();
          this.setDeviceClasses(this.originalConfiguration.alarmDevices[0]);
        });
    }
  }

  onSubmit() {
    if (this.activeStep === 0) {
      this.deviceClassSelectorForm.markAllAsTouched();
      if (!this.deviceClassSelectorForm.valid) {
        return;
      }
    } else if (this.activeStep === 1) {
      if (this.deviceSelectorForm) {
        this.deviceSelectorForm.markAllAsTouched();
      }

      if (!this.selectedAlarmDevices?.length) {
        return;
      }

      if (this.skipAttachments) {
        this.selectedAttachments = [];
        this.configure();
      }
    } else if (this.activeStep === 2) {
      this.configure();
    }
    this.configurationWizard.setActiveStepValid();
    this.configurationWizard.nextStep();
  }

  protected updateSelectedAlarmDevices(alarmDevices: Product[]) {
    this.selectedAlarmDevices = alarmDevices;
    this.updateIsOriginalAlarmDevicesSelected();
    this.updateAvailableSteps();
  }

  protected updateAvailableSteps() {
    this.skipAttachments = this.isAttachmentsStepSkipped();
    this.steps = this.skipAttachments ? 2 : 3;
  }

  private setDeviceClasses(alarmDevice: Product) {
    this.selectedSafetyClass = alarmDevice.fullType.includes("ex") ? SafetyClass.EX : SafetyClass.NON_EX;
    this.selectedVoltageClass = alarmDevice.fullType.includes("230") ? VoltageClass.VOLT_230 : VoltageClass.VOLT_24;
  }

  private isAttachmentsStepSkipped(): boolean {
    return (
      this.selectedSafetyClass === SafetyClass.EX || !this.selectedAlarmDevices?.some((device) => device.relationships?.length)
    );
  }

  private configure() {
    this.originalConfiguration
      ? this.updateAlarmDeviceConfig(this.originalConfiguration.productConfiguration!)
      : this.createNewAlarmDeviceConfig();
  }

  private createNewAlarmDeviceConfig() {
    const alarmDeviceConfig = AlarmDeviceConfiguration.create(
      this.project,
      this.selectedAlarmDevices!,
      this.selectedAttachments.map((attachment) => attachment.id),
    );
    this.project.addAlarmDevices(alarmDeviceConfig);
    this.backButton.navigateBack();
  }

  private updateAlarmDeviceConfig(originalConfig: AlarmDeviceConfiguration) {
    const attachmentIds = this.selectedAttachments.map((attachment) => attachment.id);
    originalConfig.update(this.selectedAlarmDevices!, attachmentIds);
    this.backButton.navigateBack();
  }

  private updateIsOriginalAlarmDevicesSelected() {
    if (!(this.selectedAlarmDevices && this.originalConfiguration?.alarmDevices)) {
      this.isOriginalAlarmDevicesSelected = false;
      return;
    }
    this.isOriginalAlarmDevicesSelected = this.selectedAlarmDevices.every((product) =>
      this.originalConfiguration!.alarmDevices!.map((originalAlarmDevice) => originalAlarmDevice.id).includes(product.id),
    );
  }
}
