import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { Project } from "@domain/project/project";
import { DraegerFonts } from "@pdf/helpers/draeger-fonts";
import { ContainerProperties, FontNames, ImagePaths, PdfProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";

@Injectable({
  providedIn: "root",
})
export class PdfFooterService {
  private static readonly FONT_SIZE: number = 25 * PdfProperties.POINT_TO_PIXEL_RATIO;
  private static readonly NAVINTA_FONT_SIZE: number = 40 * PdfProperties.POINT_TO_PIXEL_RATIO;

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  addToEachPage(pdf: jsPDF, project: Project, fileName: string, exportTime: Date) {
    this.addFonts(pdf);
    pdf.setFont(FontNames.DRAEGER_PANGEA, "normal");
    const numberOfPages = pdf.getNumberOfPages();
    for (let index = 2; index <= numberOfPages; index++) {
      pdf.setPage(index);
      pdf.addImage(ImagePaths.DRAEGERLOGO, "PNG", ContainerProperties.LEFT_X, 2333, 145, 72);
      pdf.addImage(ImagePaths.NAVINTALOGO, "PNG", ContainerProperties.LEFT_X + 145 + 24, 2315, 100, 100);
      pdf.setFontSize(PdfFooterService.NAVINTA_FONT_SIZE);
      pdf.text("Navinta", ContainerProperties.LEFT_X + 293, 2385);
      pdf.setFontSize(PdfFooterService.FONT_SIZE);
      pdf.text(
        this.truncateTextIfTooLong(pdf, project.name) + " | " + this.truncateTextIfTooLong(pdf, project.customer.name || ""),
        700,
        2385,
      );
      pdf.setTextColor(PdfProperties.ODX_GREY);
      pdf.text(
        $localize`:@@pdfExport.footer.timestamp:${fileName}:fileName:, exportiert am ${exportTime.toLocaleDateString(this.localeId)}:exportDate:, ${exportTime.toLocaleTimeString(this.localeId)}:exportTime:`,
        3257,
        2385,
        { align: "right" },
      );
      pdf.setTextColor(PdfProperties.DRAEGERBLUE);
      pdf.text($localize`:@@pdfExport.footer.pageNumber:s. ${index}:currentPage:/${numberOfPages}:totalPages:`, 3386, 2385, {
        align: "right",
      });
    }
  }

  private truncateTextIfTooLong(pdf: jsPDF, text: string) {
    let splitText: string[] = pdf.splitTextToSize(text, 780);
    if (splitText.length > 1) {
      splitText[0] = splitText[0] + " ...";
    }
    return splitText[0];
  }

  private addFonts(doc: jsPDF) {
    new DraegerFonts().fonts.forEach((font) => {
      doc.addFileToVFS(font.ttfName, font.base64);
      doc.addFont(font.ttfName, font.fontName, font.fontWeight);
    });
  }
}
