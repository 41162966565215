import { Injectable } from "@angular/core";
import { SortProductListService } from "@components/project-detail-header/sort-product-list.service";
import { ProductData } from "@domain/project/product-data/product-data";
import { ProductDataService } from "@domain/project/product-data/product-data.service";
import { Project } from "@domain/project/project";
import { ProjectRelatedService } from "@domain/project/project-related-service";
import { ProjectCostService } from "@pages/project/project-details/product-list/project-cost.service";
import Excel, { Cell, Fill } from "exceljs";
import { forkJoin } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ExportProductListService {
  private readonly LINE_BREAK = " \r\n"; // whitespace before linebreak fixes bug

  fontBold = { name: "Arial", size: 10, bold: true };
  fontNormal = { name: "Arial", size: 10 };
  fill: Fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "F8CBAD" },
  };
  greyFill: Fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D0CECE" },
  };
  border: Excel.Border = {
    style: "medium",
    color: { argb: "000000" },
  };
  thinBorder: Excel.Border = {
    style: "thin",
    color: { argb: "000000" },
  };

  constructor(
    private productDataService: ProductDataService,
    private projectCostService: ProjectCostService,
    private readonly sortProductListService: SortProductListService,
  ) {}

  export(project: Project) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet($localize`:@@productListExport.worksheetName:Kalkulation`);
    const projectData$ = this.productDataService.collectProjectData(project);
    const totalProductCosts$ = this.projectCostService.calculateAllProductsTotal(project);
    const totalNetCosts$ = this.projectCostService.calculateTotalNetCosts(project);
    Excel.ValueType.Number;
    forkJoin([projectData$, totalProductCosts$, totalNetCosts$]).subscribe((results: any[]) => {
      const projectDataRaw: ProductData[][][] = results[0];
      const totalProductCost: number = results[1];
      const totalServiceCost: number = this.projectCostService.calculateServicesCosts(project);
      const totalNetCost: number = results[2];

      this.formatSheet(worksheet);

      this.addAddress(project, worksheet);

      worksheet.getCell("A4").value = $localize`:@@productListExport.column1.header:Pos.`;
      worksheet.getCell("B4").value = $localize`:@@productListExport.column2.header:Sachnummer`;
      worksheet.getCell("C4").value = $localize`:@@productListExport.column3.header:Anzahl`;
      worksheet.getCell("D4").value = $localize`:@@productListExport.column4.header:Bezeichnung`;
      worksheet.getCell("E4").value = $localize`:@@productListExport.column5.header:Einheit`;
      worksheet.getCell("F4").value = $localize`:@@productListExport.column6.header:Listenpreis`;
      worksheet.getCell("G4").value = $localize`:@@productListExport.column7.header:Rabatt`;
      worksheet.getCell("H4").value = $localize`:@@productListExport.column8.header:Einzelpreis`;
      worksheet.getCell("I4").value = $localize`:@@productListExport.column9.header:Gesamtpreis`;

      this.getCells(4, 4, 1, 9, worksheet).forEach(
        (cell) =>
          (cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          }),
      );
      worksheet.getRow(4).height = 32;
      worksheet.getRow(4).font = this.fontBold;
      let currentRow = 5;
      const sortedData = this.sortProductListService.sortProductData(projectDataRaw);
      sortedData.forEach((dataRaw, index) => {
        currentRow = this.addProductData(dataRaw, worksheet, index, currentRow);
      });

      const firstRowOfServices = currentRow;
      currentRow = this.addServices(project, worksheet, currentRow);
      currentRow = this.addTotalCosts(worksheet, currentRow, firstRowOfServices);
      this.addRequiredInformation(worksheet, currentRow);

      this.createAndDownload(workbook, project);
    });
  }

  private formatSheet(worksheet: Excel.Worksheet) {
    worksheet.getRow(3).height = 128;

    worksheet.getColumn(1).alignment = { horizontal: "right" };
    worksheet.getColumn(2).width = 16;
    worksheet.getColumn(2).alignment = { horizontal: "center" };
    worksheet.getColumn(3).alignment = { horizontal: "right" };
    worksheet.getColumn(4).width = 64;
    worksheet.getColumn(5).alignment = { horizontal: "center" };
    worksheet.getColumn(5).width = 16;
    worksheet.getColumn(6).width = 16;
    worksheet.getColumn(7).alignment = { horizontal: "center" };
    worksheet.getColumn(8).width = 16;
    worksheet.getColumn(9).width = 16;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 16;

    worksheet.getColumn(6).numFmt = "#,##0.00 €";
    worksheet.getColumn(7).numFmt = "##0%";
    worksheet.getColumn(8).numFmt = "#,##0.00 €";
    worksheet.getColumn(9).numFmt = "#,##0.00 €";
  }

  private addAddress(project: Project, worksheet: Excel.Worksheet) {
    const headerSubFill: Fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFF00" },
    };

    const borderLeft = {
      top: this.border,
      bottom: this.border,
      left: this.border,
    };
    const borderRight = {
      top: this.border,
      bottom: this.border,
      right: this.border,
    };
    const borderMid = { top: this.border, bottom: this.border };

    const toMergeCells = ["A1:C1", "A2:C2", "A3:C3", "F1:G1", "F2:G2", "D3:I3"];
    toMergeCells.forEach((cells) => worksheet.mergeCells(cells));
    worksheet.getCell("A1").border = { bottom: this.border };
    worksheet.getRows(1, 2)?.forEach((row) => (row.font = this.fontBold));
    worksheet.getCell("A1").value = $localize`:@@productListExport.projectName.prefix:Projektname:`;
    worksheet.getCell("A1").style.fill = this.fill;
    worksheet.getCell("A1").border = borderLeft;
    worksheet.getCell("D1").value = project.name;
    worksheet.getCell("D1").border = borderRight;
    worksheet.getCell("E1").value = $localize`:@@productListExport.requestDate.prefix:Anfragedatum:`;
    worksheet.getCell("E1").style.fill = this.fill;
    worksheet.getCell("E1").border = borderLeft;
    worksheet.getCell("F1").style.fill = headerSubFill;
    worksheet.getCell("F1").border = borderMid;
    worksheet.getCell("F1").style.numFmt = "dd.MM.yyyy";
    worksheet.getCell("H1").value = $localize`:@@productListExport.exZone.prefix:Ex-Zone:`;
    worksheet.getCell("H1").style.fill = this.fill;
    worksheet.getCell("H1").border = borderMid;
    const dropdownOptionExZone = $localize`:@@productListExport.dropdownOptions.exZone:keine Ex-Zone, Ex-Zone 0, Ex-Zone 1, Ex-Zone 2`;
    worksheet.getCell("I1").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + dropdownOptionExZone + '"'],
    };
    worksheet.getCell("I1").style.fill = headerSubFill;
    worksheet.getCell("I1").border = borderRight;
    worksheet.getCell("J1").value = $localize`:@@productListExport.showDiscounts.prefix:Rabatte anzeigen:`;
    worksheet.getCell("J1").style.fill = this.fill;
    worksheet.getCell("J1").border = borderMid;
    const dropdownOptionShowDiscounts = $localize`:@@productListExport.dropdownOptions.showDiscounts:Ja, Nein`;
    worksheet.getCell("K1").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + dropdownOptionShowDiscounts + '"'],
    };
    worksheet.getCell("K1").style.fill = headerSubFill;
    worksheet.getCell("K1").border = borderRight;

    worksheet.getCell("A2").value = $localize`:@@productListExport.customerNumber.prefix:KD-Nr.:`;
    worksheet.getCell("A2").style.fill = this.fill;
    worksheet.getCell("A2").border = borderLeft;
    worksheet.getCell("D2").value = project.customer.customerNumber;
    worksheet.getCell("D2").border = borderRight;
    worksheet.getCell("E2").style.fill = this.fill;
    worksheet.getCell("E2").border = borderLeft;
    worksheet.getCell("F2").style.fill = headerSubFill;
    worksheet.getCell("F2").border = borderMid;
    worksheet.getCell("H2").value = $localize`:@@productListExport.dustZone.prefix:Staub-Zone:`;
    worksheet.getCell("H2").style.fill = this.fill;
    worksheet.getCell("H2").border = borderMid;
    const dropdownOptionDustZone = $localize`:@@productListExport.dropdownOptions.dustZone:keine Staub-Zone, Staub-Zone 20, Staub-Zone 21, Staub-Zone 22`;
    worksheet.getCell("I2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + dropdownOptionDustZone + '"'],
    };
    worksheet.getCell("I2").style.fill = headerSubFill;
    worksheet.getCell("I2").border = borderRight;
    worksheet.getCell("J2").value = $localize`:@@productListExport.infoSSB.prefix:Info SSB vorhanden:`;
    worksheet.getCell("J2").style.fill = this.fill;
    worksheet.getCell("J2").border = borderMid;
    const dropdownOptionInfoSSB = $localize`:@@productListExport.dropdownOptions.infoSSB:Ja, Nein`;
    worksheet.getCell("K2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + dropdownOptionInfoSSB + '"'],
    };
    worksheet.getCell("K2").style.fill = headerSubFill;
    worksheet.getCell("K2").border = borderRight;

    worksheet.getCell("A3").font = this.fontNormal;
    worksheet.getCell("A3").value = $localize`:@@productListExport.address.prefix:Anschrift:`;
    worksheet.getCell("A3").border = borderLeft;
    worksheet.getCell("A3").alignment = {
      vertical: "top",
      horizontal: "right",
    };
    worksheet.getCell("D3").alignment = { vertical: "top" };
    worksheet.getCell("D3").font = this.fontNormal;
    worksheet.getCell("D3").value =
      (project.contactPerson.firstname ? project.contactPerson.firstname + " " : "") +
      (project.contactPerson.lastname ? project.contactPerson.lastname + this.LINE_BREAK : "") +
      (project.contactPerson.department ? project.contactPerson.department + this.LINE_BREAK : "") +
      (project.contactPerson.email ? project.contactPerson.email + this.LINE_BREAK : "") +
      (project.contactPerson.phoneNumber ? "Tel: " : "") +
      (project.contactPerson.phoneNumber ? project.contactPerson.phoneNumber + this.LINE_BREAK + this.LINE_BREAK : "") +
      (project.customer.name ? project.customer.name + this.LINE_BREAK : "") +
      (project.customer.street ? project.customer.street + this.LINE_BREAK : "") +
      (project.customer.zipCode ? project.customer.zipCode + " " : "") +
      (project.customer.city ? project.customer.city : "");
    worksheet.getCell("C3").border = borderMid;
    worksheet.getCell("D3").border = borderRight;

    worksheet.getCell("J3").value = $localize`:@@productListExport.salesChannel.prefix:Vertriebsweg 03:`;
    worksheet.getCell("J3").style.fill = this.fill;
    worksheet.getCell("J3").border = borderLeft;
    worksheet.getCell("J3").alignment = { vertical: "top" };
    worksheet.getCell("K3").value = $localize`:@@productListExport.technicianName.prefix:Name Techniker`;
    worksheet.getCell("K3").style.fill = headerSubFill;
    worksheet.getCell("K3").border = borderRight;
    worksheet.getCell("K3").alignment = { vertical: "top" };
  }

  private addProductData(dataRaw: ProductData[][], worksheet: Excel.Worksheet, index: number, row: number): number {
    if (dataRaw.length == 0) {
      return row;
    }
    const projectData = dataRaw
      .reduce((a, b) => a.concat(b))
      .map((data) => [
        data.position,
        data.id,
        data.productPlacements.toString(),
        data.name,
        $localize`:@@productListExport.productUnit.abbreviation:St`,
        data.productCosts,
        0,
      ]);
    if (projectData.length == 0) return row;

    // Header
    let header: string = "";
    if (index == 0) {
      header = $localize`:@@global.gasWarningCenters:Gaswarnzentralen`;
    }
    if (index == 1) {
      header = $localize`:@@productListExport.gasWarningCenterPlaceholders.header:Platzhalter (Gaswarnzentralen)`;
    }
    if (index == 2) {
      header = $localize`:@@global.transmitters:Transmitter`;
    }
    if (index == 3) {
      header = $localize`:@@productListExport.transmitterPlaceholders.header:Platzhalter (Transmitter)`;
    }
    if (index == 4) {
      header = $localize`:@@global.alarmDevices:Alarmmittel`;
    }
    if (index == 5) {
      header = $localize`:@@productListExport.alarmDevicePlaceholders.header:Platzhalter (Alarmmittel)`;
    }
    if (index == 6) {
      header = $localize`:@@global.signalElements:Leuchttransparente`;
    }
    if (index == 7) {
      header = $localize`:@@productListExport.signalElementPlaceholders.header:Platzhalter (Leuchttransparente)`;
    }
    if (index == 8) {
      header = $localize`:@@global.plasticSigns:Kunststoffschilder`;
    }
    if (index == 9) {
      header = $localize`:@@productListExport.plasticSignPlaceholders.header:Platzhalter (Kunststoffschilder)`;
    }
    worksheet.getCell(row, 4).value = header;
    worksheet.getRow(row).font = this.fontBold;
    this.getCells(row, row, 1, 9, worksheet).forEach(
      (cell) =>
        (cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F8CBAD" },
        }),
    );
    row += 1;
    // Body;
    projectData.forEach((data) => {
      worksheet.getRow(row).values = data;
      worksheet.getCell(row, 4).alignment = { wrapText: true };
      worksheet.getRow(row).font = this.fontNormal;
      // @ts-ignore
      worksheet.getCell(row, 8).value = {
        formula: "=(F" + row + " * (100% - G" + row + "))",
      };
      // @ts-ignore
      worksheet.getCell(row, 9).value = {
        formula: "=(C" + row + "*H" + row + ")",
      };
      row += 1;
    });
    return row;
  }

  private addServices(project: Project, worksheet: Excel.Worksheet, row: number): number {
    row = this.addService(
      $localize`:@@global.assembly:Montage`,
      this.projectCostService.getAssemblyPosition(project).toString(),
      project.assembly,
      "1965124",
      worksheet,
      row,
    );
    row = this.addService(
      $localize`:@@global.installation:Inbetriebnahme`,
      this.projectCostService.getInstallationPosition(project).toString(),
      project.installation,
      "1947222",
      worksheet,
      row,
    );
    row = this.addService(
      $localize`:@@global.documentation:Dokumentation`,
      this.projectCostService.getDocumentationPosition(project).toString(),
      project.documentation,
      "3721413",
      worksheet,
      row,
    );
    row = this.addService(
      $localize`:@@global.engineering:Engineering`,
      this.projectCostService.getEngineeringPosition(project).toString(),
      project.engineering,
      "1907670",
      worksheet,
      row,
    );
    row = this.addService(
      $localize`:@@global.additionalServices:Sonstige Dienstleistungen`,
      this.projectCostService.getAdditionalServicesPosition(project).toString(),
      project.additionalServices,
      "",
      worksheet,
      row,
    );
    return row;
  }

  private addService(
    name: string,
    position: string,
    service: ProjectRelatedService,
    id: string,
    worksheet: Excel.Worksheet,
    row: number,
  ): number {
    if (service.required) {
      worksheet.getCell(row, 4).value = name;
      this.getCells(row, row, 1, 9, worksheet).forEach((cell) => (cell.fill = this.fill));
      worksheet.getRow(row++).font = this.fontBold;
      worksheet.getRow(row).values = [
        position,
        id,
        "1",
        service.notes,
        $localize`:@@productListExport.serviceUnit.abbreviation:D-St`,
        ,
        ,
        ,
        service.costs!,
      ];
      worksheet.getRow(row++).font = this.fontNormal;
    }
    return row;
  }

  private addTotalCosts(worksheet: Excel.Worksheet, row: number, firstRowOfServices: number): number {
    row += 1;
    worksheet.getCell(row, 4).value = $localize`:@@global.material:Material`;
    worksheet.getCell(row, 4).border = { left: this.border, top: this.border };
    this.getCells(row, row, 5, 8, worksheet).forEach((cell) => (cell.border = { top: this.border }));
    worksheet.getCell(row, 9).value = { formula: `=SUM(I5:I${firstRowOfServices - 1})` };
    worksheet.getCell(row, 9).border = { right: this.border, top: this.border };
    worksheet.getRow(row).font = this.fontNormal;
    row += 1;
    worksheet.getCell(row, 4).value = $localize`:@@global.services:Dienstleistungen`;
    worksheet.getCell(row, 4).border = { left: this.border };
    worksheet.getCell(row, 9).value = { formula: `=SUM(I${firstRowOfServices}:I${row - 3})` };
    worksheet.getCell(row, 9).border = { right: this.border };
    worksheet.getRow(row).font = this.fontNormal;
    row += 1;
    worksheet.getCell(row, 4).value = $localize`:@@productListExport.sumNet:Summe Netto`;
    worksheet.getCell(row, 4).border = { left: this.border };
    worksheet.getCell(row, 9).value = { formula: `=I${row - 2}+I${row - 1}` };
    worksheet.getCell(row, 9).border = { right: this.border };
    row += 1;
    worksheet.getCell(row, 4).value = $localize`:@@productListExport.vat:MwSt`;
    worksheet.getCell(row, 4).border = { left: this.border };
    worksheet.getCell(row, 9).value = { formula: `=I${row - 1}*0.19` };
    worksheet.getCell(row, 9).border = { right: this.border };
    row += 1;
    worksheet.getCell(row, 4).value = $localize`:@@productListExport.sumTotal:Summe Brutto`;
    worksheet.getCell(row, 4).border = {
      left: this.border,
      bottom: this.border,
    };
    worksheet.getCell(row, 9).value = { formula: `=I${row - 2}+I${row - 1}` };
    worksheet.getCell(row, 9).border = {
      right: this.border,
      bottom: this.border,
    };
    this.getCells(row, row, 5, 8, worksheet).forEach((cell) => (cell.border = { bottom: this.border }));
    row += 1;
    return row;
  }

  private addRequiredInformation(worksheet: Excel.Worksheet, row: number) {
    row += 6;
    worksheet.mergeCells("E" + row + ":H" + row);
    worksheet.getCell(row, 4).border = {
      top: this.thinBorder,
      left: this.thinBorder,
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 5).border = {
      top: this.thinBorder,
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 4).style.font = this.fontBold;
    worksheet.getCell(row, 4).style.fill = this.greyFill;
    worksheet.getCell(row, 5).style.fill = this.greyFill;
    worksheet.getCell(row, 4).value = $localize`:@@productListExport.checklist.header:Benötigte Informationen vom VI`;
    row += 1;
    worksheet.mergeCells("E" + row + ":H" + row);
    worksheet.getCell(row, 4).border = {
      left: this.thinBorder,
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 5).border = {
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 4).style.font = this.fontNormal;
    worksheet.getCell(row, 4).value = $localize`:@@productListExport.checklist.item1:Bindesfrist Angebot`;
    worksheet.getCell(row, 5).style.font = this.fontBold;
    const dropdownOptionChecklistItem1 = $localize`:@@productListExport.checklist.dropdownOptions.item1:2 Wochen, 4 Wochen, 3 Monate, 12 Monate, Ende des aktuellen Jahres, Benutzerdefiniert`;
    worksheet.getCell(row, 5).dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + dropdownOptionChecklistItem1 + '"'],
    };
    row += 1;
    worksheet.mergeCells("E" + row + ":H" + row);
    worksheet.getCell(row, 4).border = {
      left: this.thinBorder,
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 5).border = {
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 4).style.font = this.fontNormal;
    worksheet.getCell(row, 4).value = $localize`:@@productListExport.checklist.item2:Angebot nach VOB`;
    worksheet.getCell(row, 5).style.font = this.fontBold;
    const dropdownOptionChecklistItem2 = $localize`:@@productListExport.checklist.dropdownOptions.item2:Ja, Nein`;
    worksheet.getCell(row, 5).dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + dropdownOptionChecklistItem2 + '"'],
    };
    row += 1;
    worksheet.mergeCells("E" + row + ":H" + row);
    worksheet.getCell(row, 4).border = {
      left: this.thinBorder,
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 5).border = {
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 4).style.font = this.fontNormal;
    worksheet.getCell(row, 4).value =
      $localize`:@@productListExport.checklist.item3:Angebotsnachverfolgung durch IVI/VI/Callcenter FGDS?`;
    worksheet.getCell(row, 5).style.font = this.fontBold;
    const dropdownOptionChecklistItem3 = $localize`:@@productListExport.checklist.dropdownOptions.item3:IVI, VI, Callcenter FGDS`;
    worksheet.getCell(row, 5).dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + dropdownOptionChecklistItem3 + '"'],
    };
    row += 1;
    worksheet.mergeCells("E" + row + ":H" + row);
    worksheet.getCell(row, 4).border = {
      left: this.thinBorder,
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 5).border = {
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 4).style.font = this.fontNormal;
    worksheet.getCell(row, 4).value = $localize`:@@productListExport.checklist.item4:Gewährleistungsfristen`;
    worksheet.getCell(row, 5).style.font = this.fontBold;
    const dropdownOptionChecklistItem4 = $localize`:@@productListExport.checklist.dropdownOptions.item4:nach VOB, Standard`;
    worksheet.getCell(row, 5).dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + dropdownOptionChecklistItem4 + '"'],
    };
    row += 1;
    worksheet.mergeCells("E" + row + ":H" + row);
    worksheet.getCell(row, 4).border = {
      left: this.thinBorder,
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 5).border = {
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 4).style.font = this.fontNormal;
    worksheet.getCell(row, 4).value = $localize`:@@productListExport.checklist.item5:Endbestimmungsland`;
    worksheet.getCell(row, 5).style.font = this.fontBold;
    row += 1;
    worksheet.mergeCells("E" + row + ":H" + row);
    worksheet.getCell(row, 4).border = {
      left: this.thinBorder,
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 5).border = {
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 4).style.font = this.fontNormal;
    worksheet.getCell(row, 4).value = $localize`:@@productListExport.checklist.item6:Wiedervorlage nach`;
    worksheet.getCell(row, 5).style.font = this.fontBold;
    const dropdownOptionChecklistItem6 = $localize`:@@productListExport.checklist.dropdownOptions.item6:2 Wochen, 4 Wochen, 3 Monate, 12 Monate, Ende des aktuellen Jahres, Benutzerdefiniert`;
    worksheet.getCell(row, 5).dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + dropdownOptionChecklistItem6 + '"'],
    };
    row += 1;
    worksheet.mergeCells("E" + row + ":H" + row);
    worksheet.getCell(row, 4).border = {
      left: this.thinBorder,
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 5).border = {
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 4).style.font = this.fontNormal;
    worksheet.getCell(row, 4).value =
      $localize`:@@productListExport.checklist.item7:Sollen dem Angebot Produktinformationen beigefügt werden`;
    worksheet.getCell(row, 5).style.font = this.fontBold;
    const dropdownOptionChecklistItem7 = $localize`:@@productListExport.checklist.dropdownOptions.item7:Ja, Nein`;
    worksheet.getCell(row, 5).dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + dropdownOptionChecklistItem7 + '"'],
    };
    row += 1;
    worksheet.mergeCells("E" + row + ":H" + row);
    worksheet.getCell(row, 4).border = {
      left: this.thinBorder,
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 5).border = {
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 4).style.font = this.fontNormal;
    worksheet.getCell(row, 4).value = $localize`:@@productListExport.checklist.item8:Angebotsversendung durch...`;
    worksheet.getCell(row, 5).style.font = this.fontBold;
    const dropdownOptionChecklistItem8 = $localize`:@@productListExport.checklist.dropdownOptions.item8:SB, VI, IVI`;
    worksheet.getCell(row, 5).dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + dropdownOptionChecklistItem8 + '"'],
    };
    row += 1;
    worksheet.mergeCells("E" + row + ":H" + row);
    worksheet.getCell(row, 4).border = {
      left: this.thinBorder,
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 5).border = {
      bottom: this.thinBorder,
      right: this.thinBorder,
    };
    worksheet.getCell(row, 4).style.font = this.fontNormal;
    worksheet.getCell(row, 4).value =
      $localize`:@@productListExport.checklist.item9:Benutzerdefinierte Bindefrist und/oder Wiedervorlage (Freitext)`;
    worksheet.getCell(row, 5).style.font = this.fontBold;
  }

  private createAndDownload(workbook: Excel.Workbook, project: Project) {
    workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileURL = window.URL.createObjectURL(blob);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      const fileName = `${project.name}.xlsx`;
      fileLink.setAttribute("download", fileName);
      fileLink.setAttribute("target", "_blank");
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    });
  }

  private getCells(fromRow: number, toRow: number, fromCol: number, toCol: number, worksheet: Excel.Worksheet): Cell[] {
    let cells = [];
    for (let col = fromCol; col <= toCol; col++) {
      for (let row = fromRow; row <= toRow; row++) {
        cells.push(worksheet.getCell(row, col));
      }
    }
    return cells;
  }
}
