import { AsyncPipe, NgIf } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { LanguageService } from "@app/language.service";
import { CountrySelectionMenuComponent } from "@components/locales-selection-menu/country-selection-menu/country-selection-menu.component";
import { LanguageSelectionMenuComponent } from "@components/locales-selection-menu/language-selection-menu/language-selection-menu.component";
import { User } from "@domain/user/user";
import { UserService } from "@domain/user/user.service";
import { ButtonComponent } from "@odx/angular/components/button";
import { ToastHelper } from "@utils/toast-helper.service";
import { Observable, catchError, of, switchMap } from "rxjs";

@Component({
  selector: "app-locales-selection-menu",
  standalone: true,
  imports: [LanguageSelectionMenuComponent, ButtonComponent, CountrySelectionMenuComponent, AsyncPipe, NgIf],
  templateUrl: "./locales-selection-menu.component.html",
  styleUrl: "./locales-selection-menu.component.scss",
})
export class LocalesSelectionMenuComponent {
  protected selectedCountry?: string;
  protected selectedLanguage?: string;
  protected user$: Observable<User>;
  @Output() private select: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private languageService: LanguageService,
    private userService: UserService,
    private toastHelper: ToastHelper,
  ) {
    this.user$ = this.userService.user$;
  }

  protected onClickSelectLanguage(selection: string) {
    this.selectedLanguage = selection;
  }

  protected onClickSelectCountry(selection: string) {
    this.selectedCountry = selection;
  }

  protected async onSubmit() {
    if (!navigator.onLine) {
      this.toastHelper.errorOffline();
    }

    let countryUpdate$ = this.selectedCountry ? this.updateCountry(this.selectedCountry) : of(null);
    let languageUpdate$ = this.selectedLanguage ? this.updateLanguage(this.selectedLanguage) : of(null);

    countryUpdate$.pipe(switchMap(() => languageUpdate$)).subscribe({
      next: () => {
        this.select.emit();
      },
      error: (error) => {
        console.error("Error in onSubmit:", error);
      },
    });
  }

  private updateLanguage(selectedLanguage: string) {
    return this.languageService.switchLanguage(selectedLanguage).pipe(
      catchError((error) => {
        console.error("Error switching language:", error);
        return of(null);
      }),
    );
  }

  private updateCountry(selectedCountry: string) {
    return this.userService.updateCountry(selectedCountry).pipe(
      catchError((error) => {
        console.error("Error updating country:", error);
        return of(null);
      }),
    );
  }
}
