import { Component } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { ConfiguredProductComponent } from "@project/floorplanner/floorplan-menu/configured-product/configured-product.component";
import { FloorplanProductMenuComponent } from "@project/floorplanner/floorplan-menu/floorplan-product-menu/floorplan-product-menu.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";

@Component({
  selector: "app-floorplan-gas-warning-center-menu",
  templateUrl: "./floorplan-gas-warning-center-menu.component.html",
  styleUrls: ["./floorplan-gas-warning-center-menu.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanProductMenuComponent, ConfiguredProductComponent],
})
export class FloorplanGasWarningCenterMenuComponent {
  protected floorplan: Floorplan;

  constructor(private floorplanService: FloorplanService) {
    this.floorplan = floorplanService.selectedFloorplan;
  }

  protected addGasWarningCenter(gasWarningCenter: GasWarningCenterConfiguration) {
    this.floorplanService.addGasWarningCenter(gasWarningCenter);
  }

  protected addPlaceholder() {
    this.floorplanService.addGasWarningCenterPlaceholder();
  }

  protected deleteGasWarningCenter(gasWarningCenter: GasWarningCenterConfiguration) {
    this.floorplan.project.deleteGasWarningCenter(gasWarningCenter);
  }
}
