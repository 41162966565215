import { Component, EventEmitter, Inject, LOCALE_ID, OnDestroy, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { DataUrlMapperService } from "@utils/data-url-mapper.service";
import { PdfToImageService } from "@utils/pdf-to-image.service";
import { ToastHelper } from "@utils/toast-helper.service";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-floorplan-upload-overlay",
  templateUrl: "./floorplan-upload-overlay.component.html",
  styleUrls: ["./floorplan-upload-overlay.component.scss"],
  standalone: true,
  imports: [UiKitModule],
})
export class FloorplanUploadOverlayComponent implements OnDestroy {
  @Output() floorplan$ = new EventEmitter<UploadedFloorplan>();

  private readonly _destroying = new Subject<void>();

  constructor(
    private toastHelper: ToastHelper,
    private dataUrlMapperService: DataUrlMapperService,
    private pdfToImageService: PdfToImageService,
    @Inject(LOCALE_ID) private localeId: string,
  ) {}

  ngOnDestroy(): void {
    this._destroying.next();
    this._destroying.complete();
  }

  onEmptySelected() {
    const date = new Date();
    let plan = new UploadedFloorplan(
      $localize`:@@floorplanUpload.emptyFloorplan.name:Leerer Grundrissplan - ${date.toLocaleDateString(this.localeId)}:date:, ${date.toLocaleDateString(this.localeId)}:time:`,
      undefined,
    );
    this.floorplan$.emit(plan);
  }

  async onFileSelected(event: any) {
    // I rely on the mime type here and don't do extra security since I don't expect malicious content
    let viewport = null;
    for (let file of event.target.files) {
      if (file.type == "application/pdf") {
        viewport = await this.savePDFAsImage(file, viewport);
      } else {
        await this.saveImgAsImage(file);
      }
    }
    event.target.value = null; // must be set to be able to upload the same file multiple times
  }

  private async savePDFAsImage(file: any, viewport: any) {
    let canvas;
    try {
      canvas = await this.pdfToImageService.convert(file, viewport);
    } catch (e) {
      this.toastHelper.error(
        $localize`:@@toast.floorplanUpload.invalidPdf.title:Invalides PDF`,
        $localize`:@@toast.floorplanUpload.invalidPdf.message:Das hochgeladene PDF konnte nicht verarbeitet werden.`,
      );
      return;
    }
    const plan = new UploadedFloorplan(file.name, canvas!.toDataURL("image/jpeg"));
    this.floorplan$.emit(plan);
    return viewport;
  }

  private async saveImgAsImage(file: File) {
    this.dataUrlMapperService
      .fileToDataURL(file)
      .pipe(takeUntil(this._destroying))
      .subscribe((url) => {
        this.getImage(url).then((img) => {
          let w = (img as HTMLImageElement).width;
          let h = (img as HTMLImageElement).height;

          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = w;
          canvas.height = h;

          if (context) {
            context.drawImage(
              <HTMLImageElement | SVGImageElement | HTMLVideoElement | HTMLCanvasElement | ImageBitmap>img,
              0,
              0,
              w,
              h,
              0,
              0,
              w,
              h,
            );
            let plan = new UploadedFloorplan(file.name, canvas.toDataURL("image/jpeg"));
            this.floorplan$.emit(plan);
          }
        });
      });
  }

  private async getImage(url: string) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }
}

export class UploadedFloorplan {
  constructor(
    public name: string,
    public fileUrl?: string,
  ) {}
}
