<div class="odx-bar-parent h-100" odxLayout="flex">
  <app-configuration-wizard-back-button #backButton [project]="project" [floorplanGroup]="FloorplanMenuType.PLASTIC_SIGN">
  </app-configuration-wizard-back-button>

  <odx-wizard #configurationWizard [vertical]="true" (activeStepChanged)="activeStep = $event">
    <odx-wizard-step i18n="@@plasticSignConfiguration.wizard.step1">Kunststoffschild</odx-wizard-step>
    <odx-wizard-step i18n="@@plasticSignConfiguration.wizard.step2">Beschriftung</odx-wizard-step>
  </odx-wizard>
  <app-plastic-sign-selector
    class="w-100"
    [hidden]="activeStep !== 0"
    [project]="project"
    [originalPlasticSign]="originalConfiguration?.plasticSign"
    (plasticSignSelect$)="selectedPlasticSign = $event"
    (formReady)="selectedPlasticSignForm = $event"
  ></app-plastic-sign-selector>
  <app-plastic-sign-specs
    *ngIf="selectedPlasticSign"
    class="w-100"
    [hidden]="activeStep !== 1"
    [selectedProduct]="
      selectedPlasticSign.id === originalConfiguration?.plasticSign?.id
        ? { selectedPlasticSign: selectedPlasticSign, preselectedSpecs: originalConfiguration!.specs }
        : { selectedPlasticSign: selectedPlasticSign }
    "
    [showCosts]="project.showCostFlag"
    (specs$)="selectedSpecs = $event"
    (formReady)="selectedSpecsForm = $event"
  ></app-plastic-sign-specs>

  <app-product-config-stepper-bar
    (close)="backButton.navigateBack()"
    (next)="onSubmit()"
    (previous)="configurationWizard.previousStep()"
    [activeStep]="activeStep"
    [steps]="2"
  >
  </app-product-config-stepper-bar>
</div>
