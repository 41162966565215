import { Injectable } from "@angular/core";
import { DeviceNotRecommendedModalComponent } from "@components/device-not-recommended-modal/device-not-recommended-modal.component";
import { ModalService } from "@odx/angular/components/modal";
import { AngularDeviceInformationService } from "angular-device-information";
import { Observable, of, Subscriber, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DeviceSizeValidationService {
  constructor(
    private modalService: ModalService,
    private deviceInformationService: AngularDeviceInformationService,
  ) {}

  showHint(): Observable<boolean> {
    if (this.deviceInformationService.isDesktop()) {
      return of(false);
    }

    return new Observable<boolean>((observer) => {
      let modalRef = this.openModal();
      modalRef.onClose$.subscribe(() => this.completeObserver(observer));

      // This will show the modal after setting cookie consent
      modalRef.onDestroy$.pipe(switchMap(() => this.openModal().onClose$)).subscribe(() => this.completeObserver(observer));
    });
  }

  private completeObserver(observer: Subscriber<boolean>) {
    observer.next(true);
    observer.complete();
  }

  openModal() {
    return this.modalService.open(DeviceNotRecommendedModalComponent, {
      dismissable: false,
      data: {
        introduction: $localize`:@@modal.deviceSize.introduction:Die Navinta Software ist `,
        highlighted: $localize`:@@modal.deviceSize.highlighted:aktuell für dieses Gerät nicht optimiert.`,
        solution: $localize`:@@modal.deviceSize.solution:Es wird empfohlen auf ein Desktop- oder Laptopgerät zu wechseln.`,
      },
    });
  }
}
