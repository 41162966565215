import { Component } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ToggleLockButtonComponent } from "@components/toggle-lock-button/toggle-lock-button.component";
import { ToggleVisibilityButtonComponent } from "@components/toggle-visibility-button/toggle-visibility-button.component";
import { FloorplanState } from "@domain/project/floorplan/floorplan-state";
import { FloorplanMenuItemButtonsComponent } from "@project/floorplanner/floorplan-menu/floorplan-menu-item-buttons/floorplan-menu-item-buttons.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";

@Component({
  selector: "app-floorplan-danger-area-menu",
  templateUrl: "./floorplan-danger-area-menu.component.html",
  styleUrls: ["./floorplan-danger-area-menu.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanMenuItemButtonsComponent, ToggleVisibilityButtonComponent, ToggleLockButtonComponent],
})
export class FloorplanDangerAreaMenuComponent {
  protected floorplanState: FloorplanState;
  protected tooltipText: string = $localize`:@@floorplan.button.addDangerArea:Gefahrenbereich platzieren`;

  constructor(private floorplanService: FloorplanService) {
    this.floorplanState = floorplanService.selectedFloorplan.floorplanState;
  }

  addDangerArea() {
    this.floorplanService.addDangerArea();
  }
}
