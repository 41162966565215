import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { Observable, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProjectResolver {
  constructor(
    private projectService: ProjectService,
    private router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project | undefined> {
    return this.projectService.getById(route.params["id"]).pipe(
      tap((project) => {
        if (!project) {
          this.router.navigate([""]);
        }
      }),
    );
  }
}
