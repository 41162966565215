import { Pipe, PipeTransform } from "@angular/core";

export interface Country {
  countryCode: string;
  displayName: string;
}

export class Countries {
  static readonly COUNTRIES: Country[] = [{ countryCode: "DE", displayName: $localize`:@@country.de:Deutschland` }];
}

@Pipe({
  standalone: true,
  name: "mapCountryName",
})
export class MapCountryNamePipe implements PipeTransform {
  transform(countryCode: string): string {
    const country = Countries.COUNTRIES.find((country) => country.countryCode === countryCode);
    return country ? country.displayName : countryCode;
  }
}
