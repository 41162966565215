import { Component, inject } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { CameraModalComponent } from "@components/image-upload-overlay/camera-modal/camera-modal.component";
import { ToggleLockButtonComponent } from "@components/toggle-lock-button/toggle-lock-button.component";
import { ToggleVisibilityButtonComponent } from "@components/toggle-visibility-button/toggle-visibility-button.component";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { ProjectImage } from "@domain/project/project-image";
import { ModalService } from "@odx/angular/components/modal";
import { FloorplanMenuItemButtonsComponent } from "@project/floorplanner/floorplan-menu/floorplan-menu-item-buttons/floorplan-menu-item-buttons.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";
import { DataUrlMapperService } from "@utils/data-url-mapper.service";
import { forkJoin, map } from "rxjs";

@Component({
  selector: "app-floorplan-image-menu",
  templateUrl: "./floorplan-image-menu.component.html",
  styleUrls: ["./floorplan-image-menu.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanMenuItemButtonsComponent, ToggleLockButtonComponent, ToggleVisibilityButtonComponent],
})
export class FloorplanImageMenuComponent {
  protected floorplan: Floorplan;

  private readonly modalService = inject(ModalService);

  constructor(
    private floorplanService: FloorplanService,
    private dataUrlMapperService: DataUrlMapperService,
  ) {
    this.floorplan = floorplanService.selectedFloorplan;
  }

  addImage(projectImage: ProjectImage) {
    this.floorplanService.addImage(projectImage);
  }

  openCamera() {
    let modalRef = this.modalService.open(CameraModalComponent);
    modalRef.onClose$.subscribe((file) => {
      this.dataUrlMapperService
        .fileToDataURL(file)
        .pipe(map((fileUrl) => ProjectImage.create(fileUrl, $localize`:@@imageUpload.defaultName:Platzhalter`)))
        .subscribe((projectImage) => {
          this.floorplan.project.addImages(projectImage);
        });
    });
  }

  onImageFileSelect(event: any) {
    forkJoin(
      [...event.target.files].map((file) => this.dataUrlMapperService.fileToDataURL(file).pipe(map((url) => ({ url, file })))),
    ).subscribe((files) => {
      const images = files.map(({ url, file }) => ProjectImage.create(url, file.name));
      this.floorplan.project.addImages(...images);
      // reset value to allow for uploading the same file multiple times
      event.target.value = null;
    });
  }
}
