import { HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AppRoutingModule } from "@app/app-routing.module";
import { AppComponent } from "@app/app.component";
import { INDEXED_DB_CONFIG } from "@app/indexed-db-config";
import { UiKitModule } from "@app/ui-kit.module";
import { environment } from "@environments/environment";
import { NgxIndexedDBModule } from "ngx-indexed-db";

import { CurrencyPipe } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { I18nModule } from "@app/i18n.module";
import { FullScreenErrorComponent } from "@components/full-screen-error/full-screen-error.component";
import { LogoComponent } from "@components/logo/logo.component";
import * as Sentry from "@sentry/angular-ivy";
import { AngularDeviceInformationService } from "angular-device-information";
import { OAuthModule } from "angular-oauth2-oidc";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    UiKitModule,
    NgxIndexedDBModule.forRoot(INDEXED_DB_CONFIG),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.dataBackendUrl, environment.apiUrl],
        sendAccessToken: true,
      },
    }),
    FullScreenErrorComponent,
    LogoComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({}),
    },
    I18nModule.setLocale(),
    I18nModule.setLocaleId(),
    CurrencyPipe,
    AngularDeviceInformationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
