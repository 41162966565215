import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { debounceTime, fromEvent } from "rxjs";

@Component({
  selector: "app-filter-product",
  templateUrl: "./filter-product.component.html",
  styleUrls: ["./filter-product.component.scss"],
  standalone: true,
  imports: [UiKitModule],
})
export class FilterProductComponent implements AfterViewInit {
  @ViewChild("searchField")
  searchField!: ElementRef;
  @Output("search") search: EventEmitter<any> = new EventEmitter();

  ngAfterViewInit(): void {
    fromEvent(this.searchField.nativeElement, "input")
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.search.emit(this.searchField.nativeElement.value);
      });
  }
}
