import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { AlarmDeviceDataService } from "@domain/project/product-data/alarm-device-data.service";
import { GasWarningCenterDataService } from "@domain/project/product-data/gas-warning-center-data.service";
import { PlasticSignDataService } from "@domain/project/product-data/plastic-sign-data.service";
import { SignalElementDataService } from "@domain/project/product-data/signal-element-data.service";
import { TransmitterDataService } from "@domain/project/product-data/transmitter-data.service";
import { Project } from "@domain/project/project";
import { Observable, forkJoin, of } from "rxjs";

import { ProductType } from "@domain/product/product";
import { PlaceholderProduct } from "@domain/project/floorplan/placeholder-product";
import { ProductData, ProductDataPlaceholder } from "@domain/project/product-data/product-data";
import { ALPHABET } from "@utils/alphabet";

@Injectable({
  providedIn: "root",
})
export class ProductDataService {
  constructor(
    private gasWarningCenterDataService: GasWarningCenterDataService,
    private transmitterDataService: TransmitterDataService,
    private alarmDeviceDataService: AlarmDeviceDataService,
    private signalElementDataService: SignalElementDataService,
    private plasticSignDataService: PlasticSignDataService,
    @Inject(LOCALE_ID) private localeId: string,
  ) {}

  collectProjectData(project: Project, filter: boolean = true): Observable<ProductData[][][]> {
    const gasWarningCenterData$ = filter
      ? this.gasWarningCenterDataService.getGasWarningCenterData(
          project.gasWarningCenters.filter((gasWarningCenter) => gasWarningCenter.countPlacedProducts() > 0),
          this.localeId,
        )
      : this.gasWarningCenterDataService.getGasWarningCenterData(project.gasWarningCenters, this.localeId);
    let gasWarningCenterPlaceholders$: Observable<ProductData[][]>;
    gasWarningCenterPlaceholders$ = this.getPlaceholders(
      project.floorplans.reduce(
        (acc: FloorplanPlaceholder[], floorplan) => acc.concat(floorplan.gasWarningCenterPlaceholders),
        [],
      ),
      ProductType.GASWARNINGCENTER,
      "PG",
    );

    const transmitterData$ = this.transmitterDataService.getTransmitterData(
      project.transmitters.filter((transmitter) => transmitter.countPlacedProducts() > 0),
      this.localeId,
    );

    let transmitterPlaceholders$: Observable<ProductData[][]>;
    transmitterPlaceholders$ = this.getPlaceholders(
      project.floorplans.reduce(
        (acc: FloorplanTransmitterPlaceholder[], floorplan) => acc.concat(floorplan.transmitterPlaceholders),
        [],
      ),
      ProductType.TRANSMITTER,
      "PT",
    );

    const alarmDeviceData$ = this.alarmDeviceDataService.getAlarmDeviceData(
      project.alarmDevices.filter((alarmDevice) => alarmDevice.countPlacedProducts() > 0),
      this.localeId,
    );

    let alarmDevicePlaceholders$: Observable<ProductData[][]>;
    alarmDevicePlaceholders$ = this.getPlaceholders(
      project.floorplans.reduce((acc: FloorplanPlaceholder[], floorplan) => acc.concat(floorplan.alarmDevicePlaceholders), []),
      ProductType.ALARMDEVICE,
      "PA",
    );

    const signalElementData$ = this.signalElementDataService.getSignalElementData(
      project.signalElements.filter((signalElement) => signalElement.countPlacedProducts() > 0),
      this.localeId,
    );

    let signalElementPlaceholders$: Observable<ProductData[][]>;
    signalElementPlaceholders$ = this.getPlaceholders(
      project.floorplans.reduce((acc: FloorplanPlaceholder[], floorplan) => acc.concat(floorplan.signalElementPlaceholders), []),
      ProductType.SIGNALELEMENT,
      "PL",
    );

    const plasticSignData$ = this.plasticSignDataService.getPlasticSignData(
      project.plasticSigns.filter((plasticSign) => plasticSign.countPlacedProducts() > 0),
      this.localeId,
    );

    let plasticSignPlaceholders$: Observable<ProductData[][]>;
    plasticSignPlaceholders$ = this.getPlaceholders(
      project.floorplans.reduce((acc: FloorplanPlaceholder[], floorplan) => acc.concat(floorplan.plasticSignPlaceholders), []),
      ProductType.PLASTICSIGN,
      "PK",
    );

    return forkJoin([
      gasWarningCenterData$,
      gasWarningCenterPlaceholders$,
      transmitterData$,
      transmitterPlaceholders$,
      alarmDeviceData$,
      alarmDevicePlaceholders$,
      signalElementData$,
      signalElementPlaceholders$,
      plasticSignData$,
      plasticSignPlaceholders$,
    ]);
  }

  private getPlaceholders<T extends FloorplanPlaceholder | FloorplanTransmitterPlaceholder>(
    floorplanPlaceholders: T[],
    productType: ProductType,
    placeholderPositionPrefix: string,
  ): Observable<ProductData[][]> {
    const placeholderPlacements = floorplanPlaceholders.length;
    if (placeholderPlacements === 0) {
      return of([]);
    }
    const result: ProductData[] = floorplanPlaceholders
      .filter((placeholder: T) =>
        placeholder.products.some((placeholderProduct) => placeholderProduct.id && placeholderProduct.id.length > 0),
      )
      .flatMap((placeholder: T) =>
        placeholder.products
          .filter((placeholderProduct: PlaceholderProduct) => placeholderProduct.id && placeholderProduct.id.length)
          .map((placeholderProductWithId: PlaceholderProduct, index: number) =>
            this.createPlaceholderProductData(placeholderProductWithId, productType, placeholder, index),
          ),
      );
    const placeholdersWithoutProductId: T[] = floorplanPlaceholders.filter(
      (placeholder: T) =>
        !placeholder.products.some((placeholderProduct) => placeholderProduct.id && placeholderProduct.id.length > 0),
    );
    if (!placeholdersWithoutProductId.length) {
      return of([result]);
    }
    const placeholderProduct = ProductData.create(
      new ProductDataPlaceholder(
        $localize`:@@productList.placeholder.description:Platzhalter (noch zu konfigurieren)`,
        productType,
        0,
      ),
      placeholdersWithoutProductId.length,
      this.localeId,
    );
    placeholderProduct.position = placeholderPositionPrefix;
    result.push(placeholderProduct);
    return of([result]);
  }

  private createPlaceholderProductData<T extends FloorplanPlaceholder | FloorplanTransmitterPlaceholder>(
    placeholderProduct: PlaceholderProduct,
    productType: ProductType,
    placeholder: T,
    index: number,
  ) {
    const productData = ProductData.create(
      new ProductDataPlaceholder(placeholderProduct.name, productType, 0, placeholderProduct.id),
      1,
      this.localeId,
    );
    productData.position = `${placeholder.positionId?.split(".")[0]}.${ALPHABET[index]}`;
    return productData;
  }
}
