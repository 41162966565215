import { ExZone, ExZoneType } from "@domain/project/floorplan/zones/ex-zone";
import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import { PositionIdTextKonva } from "@project/floorplanner/konva/position-id-text-konva";
import { Group } from "konva/lib/Group";
import { Rect } from "konva/lib/shapes/Rect";
import { Text } from "konva/lib/shapes/Text";

export class ExZoneKonva {
  static init(exZone: ExZone): Group {
    let color: string = "";
    switch (exZone.exZoneType) {
      case ExZoneType.ZONE_0:
        color = "red";
        break;
      case ExZoneType.ZONE_1:
        color = "orange";
        break;
      case ExZoneType.ZONE_2:
        color = "yellow";
        break;
    }

    let group = new Group({
      x: exZone.x,
      y: exZone.y,
      height: exZone.height,
      width: exZone.width,
      rotation: exZone.rotation,
      draggable: !exZone.locked,
    });

    let rect = new Rect({
      x: 0,
      y: 0,
      height: exZone.height,
      width: exZone.width,
      fill: color,
      opacity: 0.3,
    });

    const textPadding = 4;
    let positionId = PositionIdTextKonva.init(exZone);
    positionId.padding(textPadding);
    this.setTextPosition(positionId, group);

    group.on("transform", () => {
      group.width(Math.max(5, group.width() * group.scaleX()));
      group.height(Math.max(5, group.height() * group.scaleY()));

      rect.width(group.width());
      rect.height(group.height());

      this.setTextPosition(positionId, group);

      group.scaleX(1);
      group.scaleY(1);
    });

    group.on("transformend", () =>
      exZone.updateForm(group.width() * group.scaleX(), group.height() * group.scaleY(), group.rotation(), group.x(), group.y()),
    );

    group.setAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY, exZone);
    group.add(rect);
    group.add(positionId);
    return group;
  }

  static refreshPositionId(exZone: ExZone, group: Group) {
    const text = group.getChildren()[1] as Text;
    text.text(exZone.positionId!);
    this.setTextPosition(text, group);
  }

  private static setTextPosition(text: Text, group: Group) {
    text.x(group.width() - text.width());
    text.y(group.height() - text.height());
  }
}
