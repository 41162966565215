import { IsArrayOfInstancesOfConstraint } from "@utils/class-validator/is-array-of-instances-of.constraint";
import { IsEnumValueConstraint } from "@utils/class-validator/is-enum-value.constraint";
import { IsInstanceOfConstraint } from "@utils/class-validator/is-instance-of.constraint";
import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";

export function IsEnumValue(enumType: any, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [enumType],
      validator: IsEnumValueConstraint,
    });
  };
}

export function IsInstanceOf(targetClass: any, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [targetClass],
      validator: IsInstanceOfConstraint,
    });
  };
}

export function IsArrayOfInstancesOf(targetClass: any, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [targetClass],
      validator: IsArrayOfInstancesOfConstraint,
    });
  };
}
export function IsPastDate(): PropertyDecorator {
  return function (object: Object, propertyName: string | symbol) {
    const propName = typeof propertyName === "string" ? propertyName : propertyName.toString();

    registerDecorator({
      name: "isPastDate",
      target: object.constructor,
      propertyName: propName,
      constraints: [],
      options: { message: `${propName} must be a past date` },
      validator: {
        validate(value: any) {
          if (!(value instanceof Date)) {
            return false;
          }
          const currentDate = new Date();
          const currentTimestamp = Math.floor(currentDate.getTime() / 1000) * 1000;
          const providedTimestamp = Math.floor(value.getTime() / 1000) * 1000;
          return providedTimestamp <= currentTimestamp;
        },
      },
    });
  };
}
export function IsPhoneNumberWithoutRegionCheck(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: "IsPhoneNumberWithoutRegionCheck",
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: any) {
          if (typeof value !== "string") {
            return false;
          }
          const phoneNumberPattern = /^(\+[0-9]+)?([0-9]|\/|\(|\)|\-| )+$/;
          return phoneNumberPattern.test(value);
        },
        defaultMessage(args: ValidationArguments) {
          return `${args.property} must be a valid phone number`;
        },
      },
    });
  };
}
