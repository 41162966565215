import { Component } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { ConfiguredProductComponent } from "@project/floorplanner/floorplan-menu/configured-product/configured-product.component";
import { FloorplanProductMenuComponent } from "@project/floorplanner/floorplan-menu/floorplan-product-menu/floorplan-product-menu.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";

@Component({
  selector: "app-floorplan-alarm-device-menu",
  templateUrl: "./floorplan-alarm-device-menu.component.html",
  styleUrls: ["./floorplan-alarm-device-menu.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanProductMenuComponent, ConfiguredProductComponent],
})
export class FloorplanAlarmDeviceMenuComponent {
  protected floorplan: Floorplan;

  constructor(private floorplanService: FloorplanService) {
    this.floorplan = floorplanService.selectedFloorplan;
  }

  protected addAlarmDevice(alarmDevice: AlarmDeviceConfiguration) {
    this.floorplanService.addAlarmDevice(alarmDevice);
  }

  protected addPlaceholder() {
    this.floorplanService.addAlarmDevicePlaceholder();
  }

  protected deleteAlarmDevice(alarmDevice: AlarmDeviceConfiguration) {
    this.floorplan.project.deleteAlarmDevice(alarmDevice);
  }
}
