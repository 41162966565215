import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import { Circle } from "konva/lib/shapes/Circle";

export class MonitoringAreaKonva {
  static init(transmitter: FloorplanTransmitter | FloorplanTransmitterPlaceholder) {
    const isPlaceholder = transmitter instanceof FloorplanTransmitterPlaceholder;
    let circle = new Circle({
      radius: transmitter.monitoringAreaRadius,
      fill: "#0091F733",
      stroke: "#002766",
      strokeWidth: 3,
      dash: [12, 12],
      dashEnabled: isPlaceholder,
    });

    circle.on("transform", () => {
      circle.width(Math.max(5, circle.width() * circle.scaleX()));
      circle.height(Math.max(5, circle.height() * circle.scaleY()));
      circle.scaleX(1);
      circle.scaleY(1);
    });
    circle.on("transformend", () => (transmitter.monitoringAreaRadius = circle.radius()));

    circle.setAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY, transmitter);
    return circle;
  }

  static setPosition(circle: Circle, transmitterX: number, transmitterY: number, transmitterSize: number) {
    circle.x(transmitterX + transmitterSize / 2);
    circle.y(transmitterY + transmitterSize / 2);
  }
}
