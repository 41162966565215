import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Floorplan } from "@domain/project/floorplan/floorplan";

@Injectable({
  providedIn: "root",
})
export class FloorplanResolver {
  constructor(private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Floorplan | undefined {
    const project = route.parent!.data["project"];
    const floorplan = project.getFloorplanById(route.params["floorplanId"]);
    if (!floorplan) {
      this.router.navigate([`/projects/${route.params["id"]}`]);
    }
    return floorplan;
  }
}
