<app-floorplan-item-details [floorplanItem]="image" [title]="image.projectImage.name" [duplicateEnabled]="false">
  <div class="img-maximise">
    <img [src]="image.projectImage.fileUrl!" />
    <button odxButton variant="secondary" (click)="onShowImage()">
      <odx-icon name="maximize"></odx-icon>
    </button>
  </div>
  <form odxForm [formGroup]="imageForm" [disabled]="image.locked">
    <odx-form-field i18n-label="@@floorplan.details.image.form.name.label" label="Name des Fotos">
      <input
        formControlName="name"
        odxFormFieldControl
        i18n-placeholder="@@floorplan.details.image.form.name.placeholder"
        placeholder="Name des Fotos"
        type="text"
      />
    </odx-form-field>
  </form>
</app-floorplan-item-details>
