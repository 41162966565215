import { NgClass, NgForOf, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Languages } from "@domain/user/language";

@Component({
  selector: "app-language-selection",
  standalone: true,
  imports: [NgClass, NgIf, NgForOf],
  templateUrl: "./language-selection-menu.component.html",
  styleUrl: "./language-selection-menu.component.scss",
})
export class LanguageSelectionMenuComponent {
  @Input({ required: true }) selectedLanguage!: string;
  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  protected readonly LANGUAGES = Languages.LANGUAGES;

  protected onSelect(option: string) {
    this.select.emit(option);
  }
}
