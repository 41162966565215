import { Component, inject, Input } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { Modal, ModalRef } from "@odx/angular/components/modal";

@Component({
  selector: "confirm-delete-project-modal",
  templateUrl: "./confirm-delete-project-modal.component.html",
  styleUrls: ["./confirm-delete-project-modal.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule],
})
export class ConfirmDeleteProjectModalComponent implements Modal<any, boolean> {
  @Input("$implicit")
  public readonly modalRef = inject(ModalRef<any, string>);

  formGroup = new FormGroup({
    confirmationCheckbox: new FormControl(false),
  });

  onConfirm() {
    this.modalRef.close(this.formGroup.controls["confirmationCheckbox"].value);
  }
}
