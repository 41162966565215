import { Component } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { SignalElementConfiguration } from "@domain/project/configurations/signal-element-configuration";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { ConfiguredProductComponent } from "@project/floorplanner/floorplan-menu/configured-product/configured-product.component";
import { FloorplanProductMenuComponent } from "@project/floorplanner/floorplan-menu/floorplan-product-menu/floorplan-product-menu.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";

@Component({
  selector: "app-floorplan-signal-menu",
  templateUrl: "./floorplan-signal-menu.component.html",
  styleUrls: ["./floorplan-signal-menu.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanProductMenuComponent, ConfiguredProductComponent],
})
export class FloorplanSignalMenuComponent {
  protected floorplan: Floorplan;

  constructor(private floorplanService: FloorplanService) {
    this.floorplan = floorplanService.selectedFloorplan;
  }

  protected addSignalElement(signalElement: SignalElementConfiguration) {
    this.floorplanService.addSignalElement(signalElement);
  }

  protected addPlaceholder() {
    this.floorplanService.addSignalElementPlaceholder();
  }

  deleteSignalElement(signalElement: SignalElementConfiguration) {
    this.floorplan.project.deleteSignalElement(signalElement);
  }
}
