import { Component } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { PlasticSignConfiguration } from "@domain/project/configurations/plastic-sign-configuration";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { ConfiguredProductComponent } from "@project/floorplanner/floorplan-menu/configured-product/configured-product.component";
import { FloorplanProductMenuComponent } from "@project/floorplanner/floorplan-menu/floorplan-product-menu/floorplan-product-menu.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";

@Component({
  selector: "app-floorplan-plastic-sign-menu",
  templateUrl: "./floorplan-plastic-sign-menu.component.html",
  styleUrls: ["./floorplan-plastic-sign-menu.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanProductMenuComponent, ConfiguredProductComponent],
})
export class FloorplanPlasticSignMenuComponent {
  protected floorplan: Floorplan;

  constructor(private floorplanService: FloorplanService) {
    this.floorplan = floorplanService.selectedFloorplan;
  }

  protected addPlasticSign(plasticSign: PlasticSignConfiguration) {
    this.floorplanService.addPlasticSign(plasticSign);
  }

  protected addPlaceholder() {
    this.floorplanService.addPlasticSignPlaceholder();
  }

  protected deletePlasticSign(plasticSign: PlasticSignConfiguration) {
    this.floorplan.project.deletePlasticSign(plasticSign);
  }
}
