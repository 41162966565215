import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-toggle-lock-button",
  templateUrl: "./toggle-lock-button.component.html",
  styleUrl: "./toggle-lock-button.component.scss",
  standalone: true,
  imports: [UiKitModule],
})
export class ToggleLockButtonComponent {
  @Input({ required: true }) locked!: boolean;
  @Output() toggle = new EventEmitter<boolean>();

  toggleLock() {
    this.locked = !this.locked;
    this.toggle.emit(this.locked);
  }
}
