import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import { PositionIdTextKonva } from "@project/floorplanner/konva/position-id-text-konva";
import { Group } from "konva/lib/Group";
import { Arrow } from "konva/lib/shapes/Arrow";
import { Text } from "konva/lib/shapes/Text";

import { AirPath, AirPathDirection } from "@domain/project/floorplan/air-path";

export class AirPathKonva {
  static init(airPath: AirPath): Group {
    const color = airPath.direction == AirPathDirection.SUPPLY_AIR ? "blue" : "red";
    let group = new Group({
      x: airPath.x,
      y: airPath.y,
      width: airPath.length,
      rotation: airPath.rotation,
      draggable: !airPath.locked,
    });
    let shape = new Arrow({
      x: 0,
      y: 0,
      points: [0, 0, airPath.length, 0],
      pointerLength: 15,
      pointerWidth: 15,
      fill: color,
      stroke: color,
      strokeWidth: airPath.strokeWidth,
    });
    let positionId = PositionIdTextKonva.init(airPath);

    group.height(shape.strokeWidth());
    this.setTextPosition(positionId, group);

    group.add(shape);
    group.add(positionId);

    group.on("transform", () => {
      group.width(group.width() * group.scaleX());

      shape.points([0, 0, group.width(), 0]);
      shape.strokeWidth(shape.strokeWidth() * group.scaleY());

      group.height(shape.strokeWidth());

      this.setTextPosition(positionId, group);

      group.scaleX(1);
      group.scaleY(1);
    });

    group.on("transformend", () => airPath.updateForm(group.width(), group.height(), group.rotation(), group.x(), group.y()));

    group.setAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY, airPath);

    return group;
  }

  static refreshPositionId(airPath: AirPath, group: Group) {
    const text = group.getChildren()[1] as Text;
    text.text(airPath.positionId!);
    this.setTextPosition(text, group);
  }

  private static setTextPosition(text: Text, group: Group) {
    text.rotation(-group.rotation());
    text.offsetX(text.width() / 2);
    text.offsetY(text.height() / 2);
    text.x(-0.6 * text.width());
  }
}
