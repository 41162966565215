import { FloorplanInformationTable } from "@pdf/export-services/product-information-pages/floorplan-information-pages/floorplan-information-table";
import { TableOfContents } from "@pdf/export-services/table-of-contents/table-of-contents";
import { ContainerProperties, FontNames, ImageTypes, TableProperties, TextProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { Container } from "../container/container";
import { ContainerService } from "../container/container-service";
import { RowService } from "../default-row/row-service";

export class TableService {
  containerService: ContainerService;
  rowService: RowService;

  public constructor() {
    this.containerService = new ContainerService();
    this.rowService = new RowService();
  }

  private isNewContainerRequired(requiredHeight: number, container: Container) {
    let calculatedVerticalEndOfRow =
      container.yPosition + requiredHeight + TableProperties.PADDING_12 + ContainerProperties.PADDING_Y;
    return calculatedVerticalEndOfRow > ContainerProperties.HEIGHT + ContainerProperties.Y;
  }

  private addIcon(pdf: jsPDF, icon: HTMLImageElement, xPosition: number, container: Container) {
    pdf.addImage(
      icon,
      ImageTypes.PNG,
      xPosition + TableProperties.PADDING_32,
      container.yPosition,
      TableProperties.HEADLINE_ICON_WIDTH,
      TableProperties.HEADLINE_ICON_HEIGHT,
    );
  }

  private addHeadline(pdf: jsPDF, icon: HTMLImageElement, tableHeadline: string, container: Container) {
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setFontSize(TableProperties.HEADER_FONT_SIZE);
    this.addIcon(pdf, icon, container.xPosition, container);
    pdf.text(tableHeadline, container.xPosition + TableProperties.PADDING_64, container.yPosition, {
      baseline: TextProperties.BASELINE_TOP,
    });
    pdf.setFont(FontNames.DRAEGER_PANGEA_TEXT);
  }

  private addRowHeaders(pdf: jsPDF, table: FloorplanInformationTable, container: Container) {
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    table.columns.forEach((column) => {
      pdf.text(column.title, container.xPosition + column.xPosition, container.yPosition, {
        baseline: TextProperties.BASELINE_TOP,
      });
    });
  }

  private init(pdf: jsPDF, table: FloorplanInformationTable, container: Container) {
    container.yPosition += TableProperties.HEADLINE_OFFSET_Y;
    this.addHeadline(pdf, table.icon, table.headline, container);
    container.yPosition += TableProperties.GAP_HEADLINE_HEADERS;
    this.addRowHeaders(pdf, table, container);
    container.yPosition += TableProperties.PADDING_26 + TableProperties.ROW_PADDING_BOTTOM;
    this.rowService.addSeparator(pdf, container, table.headline !== undefined);
  }

  public tableToPdf(
    pdf: jsPDF,
    table: FloorplanInformationTable,
    container: Container,
    tableOfContents: TableOfContents,
    headline: string,
  ) {
    if (this.isNewContainerRequired(TableProperties.HEADER_HEIGHT + table.rows[0].rowHeight, container)) {
      this.containerService.add(pdf, container, headline, tableOfContents);
    }
    this.init(pdf, table, container);
    table.rows.forEach((row) => {
      if (this.isNewContainerRequired(row.rowHeight, container)) {
        this.containerService.add(pdf, container, headline, tableOfContents);
        this.init(pdf, table, container);
      }
      this.rowService.add(pdf, table, row, container);
    });
    container.yPosition += TableProperties.GAP_BETWEEN_TABLES;
  }
}
