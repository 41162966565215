import { NgClass, NgForOf, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Countries } from "@domain/user/country";

@Component({
  selector: "app-country-selection",
  standalone: true,
  imports: [NgClass, NgIf, NgForOf],
  templateUrl: "./country-selection-menu.component.html",
  styleUrl: "./country-selection-menu.component.scss",
})
export class CountrySelectionMenuComponent {
  @Input({ required: true }) selectedCountry!: string;
  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  protected readonly COUNTRIES = Countries.COUNTRIES;

  protected onSelect(option: string) {
    this.select.emit(option);
  }
}
