import { Component } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ToggleLockButtonComponent } from "@components/toggle-lock-button/toggle-lock-button.component";
import { ToggleVisibilityButtonComponent } from "@components/toggle-visibility-button/toggle-visibility-button.component";
import { FloorplanState } from "@domain/project/floorplan/floorplan-state";
import { ExZoneType } from "@domain/project/floorplan/zones/ex-zone";
import { FloorplanMenuItemButtonsComponent } from "@project/floorplanner/floorplan-menu/floorplan-menu-item-buttons/floorplan-menu-item-buttons.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";

@Component({
  selector: "app-floorplan-ex-zone-menu",
  templateUrl: "./floorplan-ex-zone-menu.component.html",
  styleUrls: ["./floorplan-ex-zone-menu.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanMenuItemButtonsComponent, ToggleVisibilityButtonComponent, ToggleLockButtonComponent],
})
export class FloorplanExZoneMenuComponent {
  protected floorplanState: FloorplanState;
  protected tooltipTextAddExZone0: string = $localize`:@@floorplan.button.addExZone:Ex-Zone ${"0"}:exZoneType: platzieren`;
  protected tooltipTextAddExZone1: string = $localize`:@@floorplan.button.addExZone:Ex-Zone ${"1"}:exZoneType: platzieren`;
  protected tooltipTextAddExZone2: string = $localize`:@@floorplan.button.addExZone:Ex-Zone ${"2"}:exZoneType: platzieren`;

  constructor(private floorplanService: FloorplanService) {
    this.floorplanState = floorplanService.selectedFloorplan.floorplanState;
  }

  addExZone(exZoneType: number) {
    if (exZoneType == 0) this.addToFloorplan(ExZoneType.ZONE_0);
    if (exZoneType == 1) this.addToFloorplan(ExZoneType.ZONE_1);
    if (exZoneType == 2) this.addToFloorplan(ExZoneType.ZONE_2);
  }

  private addToFloorplan(type: ExZoneType) {
    this.floorplanService.addExZone(type);
  }
}
