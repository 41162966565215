import { Component, EventEmitter, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-duplicate-button",
  templateUrl: "./duplicate-button.component.html",
  styleUrl: "./duplicate-button.component.scss",
  standalone: true,
  imports: [UiKitModule],
})
export class DuplicateButtonComponent {
  @Output() duplicate = new EventEmitter();

  protected onClickDuplicate() {
    this.duplicate.emit();
  }
}
