import { Component } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ToggleLockButtonComponent } from "@components/toggle-lock-button/toggle-lock-button.component";
import { ToggleVisibilityButtonComponent } from "@components/toggle-visibility-button/toggle-visibility-button.component";
import { AirPathDirection } from "@domain/project/floorplan/air-path";
import { FloorplanState } from "@domain/project/floorplan/floorplan-state";
import { FloorplanMenuItemButtonsComponent } from "@project/floorplanner/floorplan-menu/floorplan-menu-item-buttons/floorplan-menu-item-buttons.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";

@Component({
  selector: "app-floorplan-air-path-menu",
  templateUrl: "./floorplan-air-path-menu.component.html",
  styleUrls: ["./floorplan-air-path-menu.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanMenuItemButtonsComponent, ToggleLockButtonComponent, ToggleVisibilityButtonComponent],
})
export class FloorplanAirPathMenuComponent {
  protected floorplanState: FloorplanState;

  constructor(private floorplanService: FloorplanService) {
    this.floorplanState = floorplanService.selectedFloorplan.floorplanState;
  }

  addSupplyAirPath() {
    this.addToFloorplan(AirPathDirection.SUPPLY_AIR);
  }

  addExhaustAirPath() {
    this.addToFloorplan(AirPathDirection.EXHAUST_AIR);
  }

  private addToFloorplan(direction: AirPathDirection) {
    this.floorplanService.addAirPath(direction);
  }
}
