import { Injectable } from "@angular/core";
import { ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";
import { AttachmentDataService } from "@domain/project/product-data/attachment-data.service";
import { ProductData } from "@domain/project/product-data/product-data";
import { ALPHABET } from "@utils/alphabet";
import { Observable, forkJoin, map, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AlarmDeviceDataService {
  constructor(
    private productService: ProductService,
    private attachmentDataService: AttachmentDataService,
  ) {}

  getAlarmDeviceData(alarmDevices: readonly AlarmDeviceConfiguration[], localeId: string): Observable<ProductData[][]> {
    if (alarmDevices.length == 0) {
      return of([]);
    }

    return forkJoin(alarmDevices.map((alarmDevice) => this.getProducts(alarmDevice, localeId))).pipe(
      map((alarmDevicesData) => alarmDevicesData.flat()),
    );
  }

  private getProducts(alarmDeviceConfig: AlarmDeviceConfiguration, localeId: string): Observable<ProductData[][]> {
    let attachmentStartingIndex = alarmDeviceConfig.productIds.length > 1 ? alarmDeviceConfig.productIds.length : 0;
    let productPlacements = alarmDeviceConfig.countPlacedProducts();

    const alarmDevicesData$ = this.getAlarmDeviceProducts(alarmDeviceConfig).pipe(
      map((products) => {
        return products.map((product, index) => {
          let productData = ProductData.create(product, productPlacements, localeId);
          productData.position = alarmDeviceConfig.positionNumber!;

          if (products.length > 1) {
            productData.position += "." + ALPHABET[index];
          }
          return [productData];
        });
      }),
    );

    const attachmentsData$ = this.attachmentDataService.getProductData(
      alarmDeviceConfig,
      alarmDeviceConfig.attachmentIds,
      localeId,
      productPlacements,
      attachmentStartingIndex,
    );

    return forkJoin([alarmDevicesData$, attachmentsData$]).pipe(
      map((results: any[]) => {
        const alarmDevicesData: ProductData[][] = results[0];
        const attachmentsData: ProductData[] = results[1];
        const lastProductIndex = alarmDevicesData.length - 1;
        alarmDevicesData[lastProductIndex] = alarmDevicesData[lastProductIndex].concat(attachmentsData);
        return alarmDevicesData;
      }),
    );
  }

  private getAlarmDeviceProducts(alarmDeviceConfig: AlarmDeviceConfiguration) {
    return forkJoin(
      alarmDeviceConfig.productIds.map((productId) =>
        this.productService.getProductById(productId).pipe(
          map((alarmDevice) => {
            if (!alarmDevice || alarmDevice.type !== ProductType.ALARMDEVICE) {
              throw new Error(`Alarm device with product id '${productId}' not found.`);
            }
            return alarmDevice;
          }),
        ),
      ),
    );
  }
}
