import { formatCurrency } from "@angular/common";
import { Injectable } from "@angular/core";
import { FullProductType } from "@domain/product/product";
import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { PlasticSignConfiguration } from "@domain/project/configurations/plastic-sign-configuration";
import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { SignalElementConfiguration } from "@domain/project/configurations/signal-element-configuration";
import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanAlarmDevice } from "@domain/project/floorplan/floorplan-alarm-device";
import { FloorplanGasWarningCenter } from "@domain/project/floorplan/floorplan-gas-warning-center";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanPlasticSign } from "@domain/project/floorplan/floorplan-plastic-sign";
import { FloorplanProductItem } from "@domain/project/floorplan/floorplan-product-item";
import { FloorplanSignalElement } from "@domain/project/floorplan/floorplan-signal-element";
import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { PlaceholderProduct } from "@domain/project/floorplan/placeholder-product";
import { AlarmDeviceDataService } from "@domain/project/product-data/alarm-device-data.service";
import { GasWarningCenterDataService } from "@domain/project/product-data/gas-warning-center-data.service";
import { PlasticSignDataService } from "@domain/project/product-data/plastic-sign-data.service";
import { ProductData } from "@domain/project/product-data/product-data";
import { SignalElementDataService } from "@domain/project/product-data/signal-element-data.service";
import { TransmitterDataService } from "@domain/project/product-data/transmitter-data.service";
import { Project } from "@domain/project/project";
import { ProjectRelatedService } from "@domain/project/project-related-service";
import { UserService } from "@domain/user/user.service";
import { ProductListServiceRow } from "@pdf/export-services/product-information-pages/product-list-pages/product-list-service-row";
import { ServicesTable } from "@pdf/export-services/product-information-pages/product-list-pages/services-table";
import { TableOfContents } from "@pdf/export-services/table-of-contents/table-of-contents";
import { Container } from "@pdf/helpers/container/container";
import { ContainerService } from "@pdf/helpers/container/container-service";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import {
  ContainerProperties,
  FontNames,
  Headlines,
  ImagePaths,
  PdfProperties,
  ProductListProperties,
  TableProperties,
} from "@pdf/pdf-properties";
import { ALPHABET } from "@utils/alphabet";
import { jsPDF } from "jspdf";
import { first, forkJoin, map } from "rxjs";
import { ProductListPlaceholderRow } from "./product-list-placeholder-row";
import { ProductListProductRow } from "./product-list-product-row";
import { ProductListTable } from "./product-list-table";

interface ProductTableRow {
  position: string;
  quantity: string;
  name: string;
  id: string;
  productCosts: number;
  totalCosts: number;
  isAvailable: boolean;
  isPlaceholder: boolean;
}

interface ServicesTableRow {
  position: number;
  title: string;
  description: string;
  id: string;
  costs: number;
}

interface OccurrencesPerConfig {
  [configId: string]: number;
}

interface ProductListProducts {
  products: ProductData[];
  occurrences: number;
}

interface ServiceData {
  service: ProjectRelatedService;
  id: string;
  title: string;
}

@Injectable({
  providedIn: "root",
})
export class ProductListPdfService {
  private headlines = new Headlines();
  private isFirstContainerDrawn = false;
  private localeId!: string;
  private currentPosition: number = 1;

  private gasWarningCenterConfigurations!: GasWarningCenterConfiguration[];
  private transmitterConfigurations!: TransmitterConfiguration[];
  private alarmDeviceConfigurations!: AlarmDeviceConfiguration[];
  private signalElementConfigurations!: SignalElementConfiguration[];
  private plasticSignConfigurations!: PlasticSignConfiguration[];

  constructor(
    private gasWarningCenterDataService: GasWarningCenterDataService,
    private transmitterDataService: TransmitterDataService,
    private alarmDeviceDataService: AlarmDeviceDataService,
    private signalElementDataService: SignalElementDataService,
    private plasticSignDataService: PlasticSignDataService,
    private containerService: ContainerService,
    private userService: UserService,
  ) {
    this.userService.user$.pipe(first()).subscribe((user) => {
      this.localeId = user.language;
    });
  }

  public generateFullProductList(pdf: jsPDF, project: Project, container: Container, tableOfContents: TableOfContents) {
    let overallProductCost = 0;
    const showCosts = project.showCostFlag;
    this.isFirstContainerDrawn = false;

    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    pdf.setLineWidth(TableProperties.SEPARATOR_WIDTH);
    pdf.setDrawColor(TableProperties.SEPARATOR_COLOR);

    let totalProductCost = 0;

    const gasWarningCenterProductData$ = this.gasWarningCenterDataService.getGasWarningCenterData(
      project.gasWarningCenters,
      this.localeId,
    );
    const transmitterProductData$ = this.transmitterDataService.getTransmitterData(project.transmitters, this.localeId);
    const alarmDeviceProductData$ = this.alarmDeviceDataService.getAlarmDeviceData(project.alarmDevices, this.localeId);
    const signalElementProductData$ = this.signalElementDataService.getSignalElementData(project.signalElements, this.localeId);
    const plasticSignProductData$ = this.plasticSignDataService.getPlasticSignData(project.plasticSigns, this.localeId);

    return forkJoin({
      gasWarningCenters: gasWarningCenterProductData$,
      transmitters: transmitterProductData$,
      alarmDevices: alarmDeviceProductData$,
      signalElements: signalElementProductData$,
      plasticSigns: plasticSignProductData$,
    }).pipe(
      map((productData) => {
        [totalProductCost, overallProductCost] = this.addProductTableForProject(
          project.floorplans.flatMap((floorplan) => floorplan.gasWarningCenters),
          project.floorplans.flatMap((floorplan) => floorplan.gasWarningCenterPlaceholders),
          [...project.gasWarningCenters.filter((config) => config.countPlacedProducts(project) > 0)],
          productData.gasWarningCenters,
          totalProductCost,
          overallProductCost,
          pdf,
          container,
          tableOfContents,
          showCosts,
          this.headlines.GASWARNINGCENTERS,
          "PG",
        );

        [totalProductCost, overallProductCost] = this.addProductTableForProject(
          project.floorplans.flatMap((floorplan) => floorplan.transmitters),
          project.floorplans.flatMap((floorplan) => floorplan.transmitterPlaceholders),
          [...project.transmitters.filter((config) => config.countPlacedProducts(project) > 0)],
          productData.transmitters,
          totalProductCost,
          overallProductCost,
          pdf,
          container,
          tableOfContents,
          showCosts,
          this.headlines.TRANSMITTERS,
          "PT",
        );

        [totalProductCost, overallProductCost] = this.addProductTableForProject(
          project.floorplans.flatMap((floorplan) => floorplan.alarmDevices),
          project.floorplans.flatMap((floorplan) => floorplan.alarmDevicePlaceholders),
          [...project.alarmDevices.filter((config) => config.countPlacedProducts(project) > 0)],
          productData.alarmDevices,
          totalProductCost,
          overallProductCost,
          pdf,
          container,
          tableOfContents,
          showCosts,
          this.headlines.ALARMDEVICES,
          "PA",
        );

        [totalProductCost, overallProductCost] = this.addProductTableForProject(
          project.floorplans.flatMap((floorplan) => floorplan.signalElements),
          project.floorplans.flatMap((floorplan) => floorplan.signalElementPlaceholders),
          [...project.signalElements.filter((config) => config.countPlacedProducts(project) > 0)],
          productData.signalElements,
          totalProductCost,
          overallProductCost,
          pdf,
          container,
          tableOfContents,
          showCosts,
          this.headlines.SIGNALELEMENTS,
          "PL",
        );

        [totalProductCost, overallProductCost] = this.addProductTableForProject(
          project.floorplans.flatMap((floorplan) => floorplan.plasticSigns),
          project.floorplans.flatMap((floorplan) => floorplan.plasticSignPlaceholders),
          [...project.plasticSigns.filter((config) => config.countPlacedProducts(project) > 0)],
          productData.plasticSigns,
          totalProductCost,
          overallProductCost,
          pdf,
          container,
          tableOfContents,
          showCosts,
          this.headlines.PLASTICSIGNS,
          "PK",
        );
        const servicesCost: number = this.addServicesTable(project, pdf, container, tableOfContents, this.headlines.SERVICES);
        if (this.isFirstContainerDrawn && showCosts) {
          this.drawProductListOutro(pdf, container, tableOfContents, servicesCost, overallProductCost);
        } else if (this.isFirstContainerDrawn) {
          container.yPosition -= TableProperties.GAP_BETWEEN_TABLES;
          this.addFooter(pdf, container);
        }
      }),
    );
  }

  public generateForFloorplan(pdf: jsPDF, floorplan: Floorplan, container: Container, tableOfContents: TableOfContents) {
    let overallProductCost = 0;
    const showCosts = floorplan.project.showCostFlag;
    this.isFirstContainerDrawn = false;
    [
      this.gasWarningCenterConfigurations,
      this.transmitterConfigurations,
      this.alarmDeviceConfigurations,
      this.signalElementConfigurations,
      this.plasticSignConfigurations,
    ] = this.filterConfigurationsByFloorplan(floorplan);

    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    pdf.setLineWidth(TableProperties.SEPARATOR_WIDTH);
    pdf.setDrawColor(TableProperties.SEPARATOR_COLOR);

    let totalProductCost = 0;

    const gasWarningCenterProductData$ = this.gasWarningCenterDataService.getGasWarningCenterData(
      this.gasWarningCenterConfigurations,
      this.localeId,
    );
    const transmitterProductData$ = this.transmitterDataService.getTransmitterData(this.transmitterConfigurations, this.localeId);
    const alarmDeviceProductData$ = this.alarmDeviceDataService.getAlarmDeviceData(this.alarmDeviceConfigurations, this.localeId);
    const signalElementProductData$ = this.signalElementDataService.getSignalElementData(
      this.signalElementConfigurations,
      this.localeId,
    );
    const plasticSignProductData$ = this.plasticSignDataService.getPlasticSignData(this.plasticSignConfigurations, this.localeId);

    return forkJoin({
      gasWarningCenters: gasWarningCenterProductData$,
      transmitters: transmitterProductData$,
      alarmDevices: alarmDeviceProductData$,
      signalElements: signalElementProductData$,
      plasticSigns: plasticSignProductData$,
    }).pipe(
      map((productData) => {
        [totalProductCost, overallProductCost] = this.addProductTableForFloorplan(
          floorplan.name,
          floorplan.gasWarningCenters,
          floorplan.gasWarningCenterPlaceholders,
          this.gasWarningCenterConfigurations,
          (floorplanItem) => floorplanItem.config,
          productData.gasWarningCenters,
          totalProductCost,
          overallProductCost,
          pdf,
          container,
          tableOfContents,
          showCosts,
          this.headlines.GASWARNINGCENTERS,
          "PG",
        );

        [totalProductCost, overallProductCost] = this.addProductTableForFloorplan(
          floorplan.name,
          floorplan.transmitters,
          floorplan.transmitterPlaceholders,
          this.transmitterConfigurations,
          (floorplanItem) => floorplanItem.config,
          productData.transmitters,
          totalProductCost,
          overallProductCost,
          pdf,
          container,
          tableOfContents,
          showCosts,
          this.headlines.TRANSMITTERS,
          "PT",
        );

        [totalProductCost, overallProductCost] = this.addProductTableForFloorplan(
          floorplan.name,
          floorplan.alarmDevices,
          floorplan.alarmDevicePlaceholders,
          this.alarmDeviceConfigurations,
          (floorplanItem) => floorplanItem.config,
          productData.alarmDevices,
          totalProductCost,
          overallProductCost,
          pdf,
          container,
          tableOfContents,
          showCosts,
          this.headlines.ALARMDEVICES,
          "PA",
        );

        [totalProductCost, overallProductCost] = this.addProductTableForFloorplan(
          floorplan.name,
          floorplan.signalElements,
          floorplan.signalElementPlaceholders,
          this.signalElementConfigurations,
          (floorplanItem) => floorplanItem.config,
          productData.signalElements,
          totalProductCost,
          overallProductCost,
          pdf,
          container,
          tableOfContents,
          showCosts,
          this.headlines.SIGNALELEMENTS,
          "PL",
        );

        [totalProductCost, overallProductCost] = this.addProductTableForFloorplan(
          floorplan.name,
          floorplan.plasticSigns,
          floorplan.plasticSignPlaceholders,
          this.plasticSignConfigurations,
          (floorplanItem) => floorplanItem.config,
          productData.plasticSigns,
          totalProductCost,
          overallProductCost,
          pdf,
          container,
          tableOfContents,
          showCosts,
          this.headlines.PLASTICSIGNS,
          "PK",
        );
        if (this.isFirstContainerDrawn && showCosts) {
          this.drawFloorplanProductListOutro(pdf, container, tableOfContents, overallProductCost);
        } else if (this.isFirstContainerDrawn) {
          this.addFooter(pdf, container);
        }
      }),
    );
  }

  private drawProductListOutro(
    pdf: jsPDF,
    container: Container,
    tableOfContents: TableOfContents,
    servicesCost: number,
    overallProductCost: number,
  ) {
    if (!this.checkIfIsEnoughSpace(ProductListProperties.FULL_SUMMARY_HEIGHT, container)) {
      this.addFooter(pdf, container);
      this.containerService.add(pdf, container, this.headlines.PRODUCT_LIST, tableOfContents);
    }
    this.drawSummary(pdf, container, servicesCost, overallProductCost);
    this.drawSummaryFooter(pdf, container);
  }

  private drawFloorplanProductListOutro(
    pdf: jsPDF,
    container: Container,
    tableOfContents: TableOfContents,
    overallProductCost: number,
  ) {
    if (!this.checkIfIsEnoughSpace(ProductListProperties.SUMMARY_HEIGHT, container)) {
      this.addFooter(pdf, container);
      this.containerService.add(pdf, container, this.headlines.PRODUCT_LIST, tableOfContents);
    }
    this.drawFloorplanSummary(pdf, container, overallProductCost);
    this.drawFloorplanSummaryFooter(pdf, container);
  }

  private addProductTableForProject<T extends FloorplanProductItem<ProductConfiguration>>(
    productItems: T[],
    placeholderItems: (FloorplanPlaceholder | FloorplanTransmitterPlaceholder)[],
    configs: ProductConfiguration[],
    productData: ProductData[][],
    totalProductCost: number,
    overallProductCost: number,
    pdf: jsPDF,
    container: Container,
    tableOfContents: TableOfContents,
    showCosts: boolean,
    headline: string,
    placeholderPosition: string,
  ) {
    if (productItems.length || placeholderItems.length) {
      let productTableData: ProductTableRow[] = [];
      if (productItems.length) {
        const products = this.getProducts(configs, productData.flat(1)).filter(
          (productListProducts: ProductListProducts) => productListProducts.occurrences > 0,
        );

        productTableData = this.generateProductTableRows(products, [...productItems, ...placeholderItems]);
        totalProductCost = productTableData.reduce((acc, row) => acc + row.totalCosts, 0);
        overallProductCost += totalProductCost;
        this.currentPosition = Number(productTableData[productTableData.length - 1].position.split(".")[0]);
      }

      const placeholderData = this.generatePlaceholderData(placeholderItems, placeholderPosition);

      this.drawProductTable(
        pdf,
        container,
        tableOfContents,
        headline,
        totalProductCost,
        new ProductListTable(pdf, this.localeId, productTableData, placeholderData),
        showCosts,
      );
    }
    return [totalProductCost, overallProductCost];
  }

  private addServicesTable(
    project: Project,
    pdf: jsPDF,
    container: Container,
    tableOfContents: TableOfContents,
    headline: string,
  ): number {
    const assemblyData: ServiceData = {
      service: project.assembly,
      id: "1965124",
      title: $localize`:@@global.assembly:Montage`,
    };
    const installationData: ServiceData = {
      service: project.installation,
      id: "1947222",
      title: $localize`:@@global.installation:Inbetriebnahme`,
    };
    const documentationData: ServiceData = {
      service: project.documentation,
      id: "3721413",
      title: $localize`:@@global.documentation:Dokumentation`,
    };
    const engineeringData: ServiceData = {
      service: project.engineering,
      id: "1907670",
      title: $localize`:@@global.engineering:Engineering`,
    };
    const additionalServicesData: ServiceData = {
      service: project.additionalServices,
      id: "",
      title: $localize`:@@global.additionalServices:Sonstige Dienstleistungen`,
    };
    const requiredServices = [assemblyData, installationData, documentationData, engineeringData, additionalServicesData].filter(
      (services) => services.service.required,
    );
    if (!requiredServices.length) {
      return 0;
    }
    const servicesTableData: ServicesTableRow[] = this.generateServicesTableRows(requiredServices, this.currentPosition);
    const totalServicesCost = servicesTableData.reduce((acc, row) => acc + row.costs, 0);
    this.drawServicesTable(
      pdf,
      container,
      tableOfContents,
      headline,
      totalServicesCost,
      new ServicesTable(pdf, this.localeId, servicesTableData),
      project.showCostFlag,
    );
    return totalServicesCost;
  }

  private drawServicesTable(
    pdf: jsPDF,
    container: Container,
    tableOfContents: TableOfContents,
    headline: string,
    totalServicesCost: number,
    servicesTable: ServicesTable,
    showCosts: boolean,
  ) {
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    const servicesTableHeight: number = servicesTable.rows.map((row) => row.textHeight).reduce((acc, cur) => acc + cur, 0);
    const isEnoughSpace = this.checkIfIsEnoughSpace(
      servicesTableHeight +
        TableProperties.ROW_PADDING_Y +
        TableProperties.EMPTY_TABLE_HEIGHT +
        ProductListProperties.FULL_SUMMARY_HEIGHT +
        ProductListProperties.FOOTER_HEIGHT,
      container,
    );
    if (!this.isFirstContainerDrawn) {
      this.containerService.addOnNewPage(pdf, container, this.headlines.PRODUCT_LIST, tableOfContents);
      this.isFirstContainerDrawn = true;
    } else if (!isEnoughSpace) {
      this.addFooter(pdf, container);
      this.containerService.add(pdf, container, this.headlines.PRODUCT_LIST, tableOfContents);
    }
    this.addProductHeadline(pdf, container, headline, totalServicesCost, showCosts);
    this.addTableHeaders(pdf, container, servicesTable.columns);
    this.addRowSeparator(pdf, container, true);
    servicesTable.rows.forEach((row) => {
      const isEnoughSpace = this.checkIfIsEnoughSpace(row.textHeight + TableProperties.ROW_PADDING_Y, container);
      if (!isEnoughSpace) {
        this.addFooter(pdf, container);
        this.containerService.add(pdf, container, this.headlines.PRODUCT_LIST, tableOfContents);
        this.addProductHeadline(pdf, container, headline, totalServicesCost, showCosts);
        this.addTableHeaders(pdf, container, servicesTable.columns);
        this.addRowSeparator(pdf, container, true);
      }
      this.addServiceRow(pdf, container, servicesTable.columns, row, showCosts);
      this.addRowSeparator(pdf, container);
    });
    container.yPosition += TableProperties.GAP_BETWEEN_TABLES;
  }

  private addProductTableForFloorplan<T extends FloorplanProductItem<ProductConfiguration>>(
    floorplanName: string,
    productItems: T[],
    placeholderItems: (FloorplanPlaceholder | FloorplanTransmitterPlaceholder)[],
    configs: ProductConfiguration[],
    configFromItemFn: (floorplanItem: T) => ProductConfiguration,
    productData: ProductData[][],
    totalProductCost: number,
    overallProductCost: number,
    pdf: jsPDF,
    container: Container,
    tableOfContents: TableOfContents,
    showCosts: boolean,
    headline: string,
    position: string,
  ) {
    if (productItems.length || placeholderItems.length) {
      let productTableData: ProductTableRow[] = [];
      if (productItems.length) {
        const occurrencesPerConfig: OccurrencesPerConfig = productItems
          .map((floorplanItem) => configFromItemFn(floorplanItem))
          .reduce((acc, config) => {
            acc[config.id] = (acc[config.id] || 0) + 1;
            return acc;
          }, {} as OccurrencesPerConfig);

        const products = this.getProducts(configs, productData.flat(1), occurrencesPerConfig).filter(
          (productListProducts: ProductListProducts) => productListProducts.occurrences > 0,
        );

        productTableData = this.generateProductTableRows(products, [...productItems, ...placeholderItems]);
        totalProductCost = productTableData.reduce((acc, row) => acc + row.totalCosts, 0);
        overallProductCost += totalProductCost;
      }

      const placeholderData: ProductTableRow[] = this.generatePlaceholderData(placeholderItems, position);
      this.drawProductTable(
        pdf,
        container,
        tableOfContents,
        headline,
        totalProductCost,
        new ProductListTable(pdf, this.localeId, productTableData, placeholderData),
        showCosts,
        floorplanName,
      );
    }
    return [totalProductCost, overallProductCost];
  }

  private filterConfigurationsByFloorplan(
    floorplan: Floorplan,
  ): [
    GasWarningCenterConfiguration[],
    TransmitterConfiguration[],
    AlarmDeviceConfiguration[],
    SignalElementConfiguration[],
    PlasticSignConfiguration[],
  ] {
    const project = floorplan.project;
    const filteredGasWarningCenters = this.filterConfigsByFloorplan(project.gasWarningCenters, floorplan.gasWarningCenters);
    const filteredTransmitters = this.filterConfigsByFloorplan(project.transmitters, floorplan.transmitters);
    const filteredAlarmDevices = this.filterConfigsByFloorplan(project.alarmDevices, floorplan.alarmDevices);
    const filteredSignalElements = this.filterConfigsByFloorplan(project.signalElements, floorplan.signalElements);
    const filteredPlasticSigns = this.filterConfigsByFloorplan(project.plasticSigns, floorplan.plasticSigns);
    return [filteredGasWarningCenters, filteredTransmitters, filteredAlarmDevices, filteredSignalElements, filteredPlasticSigns];
  }

  private filterConfigsByFloorplan<T extends ProductConfiguration>(
    configs: readonly T[],
    floorplanItems:
      | FloorplanGasWarningCenter[]
      | FloorplanTransmitter[]
      | FloorplanAlarmDevice[]
      | FloorplanSignalElement[]
      | FloorplanPlasticSign[],
  ) {
    return configs.filter((config) => floorplanItems.some((floorplanItem) => floorplanItem.config.id === config.id));
  }

  private addRowSeparator(pdf: jsPDF, container: Container, strong = false) {
    if (!strong) {
      pdf.setDrawColor(TableProperties.SEPARATOR_COLOR);
    } else {
      pdf.setDrawColor(TableProperties.SEPARATOR_COLOR_STRONG);
    }
    pdf.line(
      container.xPosition + TableProperties.PADDING_X,
      container.yPosition,
      container.xPosition + TableProperties.PADDING_X + TableProperties.ROW_SEPARATOR_LENGTH,
      container.yPosition,
    );
  }

  private addProductHeadline(pdf: jsPDF, container: Container, text: string, totalProductCost: number, showCosts: boolean) {
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setFontSize(TableProperties.HEADER_FONT_SIZE);
    container.yPosition += TableProperties.HEADLINE_OFFSET_Y;
    pdf.text(text, container.xPosition + 36, container.yPosition, { baseline: "top" });
    if (showCosts) {
      this.drawTotalProductCosts(pdf, container, totalProductCost);
    }
    pdf.setFont(FontNames.DRAEGER_PANGEA_TEXT);
    container.yPosition += TableProperties.GAP_HEADLINE_HEADERS;
  }

  private getProducts(
    devices: any[],
    productData: ProductData[],
    occurrencesPerConfig?: OccurrencesPerConfig,
  ): ProductListProducts[] {
    return devices.map((config) => {
      const products: ProductData[] = config.productIds
        ? this.getProductsOfConfig(productData, config)
        : this.getProductOfConfig(productData, config);
      const sensors: ProductData[] = config.sensorId ? this.getSensorOfConfig(productData, config) : [];
      const attachments: ProductData[] = config.attachmentIds
        ? config.attachmentIds.map(
            (attachmentId: string) =>
              productData.find(
                (product: ProductData) =>
                  product.id === attachmentId && product.position!.split(".")[0] === config.positionNumber,
              )!,
          )
        : [];
      return {
        products: products.concat(sensors, attachments),
        occurrences: occurrencesPerConfig ? occurrencesPerConfig[config.id] : config.countPlacedProducts(),
      };
    });
  }

  private getProductOfConfig(
    productData: ProductData[],
    configuration:
      | GasWarningCenterConfiguration
      | TransmitterConfiguration
      | SignalElementConfiguration
      | PlasticSignConfiguration,
  ) {
    return [
      productData.find(
        (product) => product.id === configuration.productId && product.position!.split(".")[0] === configuration.positionNumber,
      )!,
    ];
  }

  private getSensorOfConfig(productData: ProductData[], configuration: TransmitterConfiguration) {
    return [
      productData.find(
        (product) => product.id === configuration.sensorId && product.position!.split(".")[0] === configuration.positionNumber,
      )!,
    ];
  }

  private getProductsOfConfig(productData: ProductData[], configuration: AlarmDeviceConfiguration) {
    return configuration.productIds.map(
      (productId) =>
        productData.find(
          (product) => product.id === productId && product.position!.split(".")[0] === configuration.positionNumber,
        )!,
    );
  }

  private generatePlaceholderData<T extends FloorplanPlaceholder | FloorplanTransmitterPlaceholder>(
    floorplanPlaceholders: T[],
    position: string,
  ): ProductTableRow[] {
    if (!floorplanPlaceholders.length) {
      return [];
    }
    const result: ProductTableRow[] = floorplanPlaceholders
      .filter((placeholder: T) =>
        placeholder.products.some((placeholderProduct) => placeholderProduct.id && placeholderProduct.id.length > 0),
      )
      .flatMap((placeholder: T) => {
        const placements: string = placeholder.notes.length ? "1*" : "1";
        return placeholder.products
          .filter((placeholderProduct: PlaceholderProduct) => placeholderProduct.id && placeholderProduct.id.length)
          .map(
            (placeholderProductWithId: PlaceholderProduct, index: number): ProductTableRow =>
              this.createPlaceholderProductTableRow(placeholderProductWithId, placeholder, index, placements),
          );
      });
    const placeholdersWithoutProductId: T[] = floorplanPlaceholders.filter(
      (placeholder: T) =>
        !placeholder.products.some((placeholderProduct) => placeholderProduct.id && placeholderProduct.id.length > 0),
    );
    if (!placeholdersWithoutProductId.length) {
      return result;
    }
    const placements = this.hasPlaceholderNotes(placeholdersWithoutProductId)
      ? floorplanPlaceholders.length.toString() + "*"
      : floorplanPlaceholders.length.toString();
    result.push({
      position: position,
      quantity: placements,
      name: $localize`:@@productList.placeholder.description:Platzhalter (noch zu konfigurieren)`,
      id: "--",
      productCosts: 0,
      totalCosts: 0,
      isAvailable: false,
      isPlaceholder: true,
    });
    return result;
  }

  private createPlaceholderProductTableRow<T extends FloorplanPlaceholder | FloorplanTransmitterPlaceholder>(
    placeholderProduct: PlaceholderProduct,
    placeholder: T,
    index: number,
    placements: string,
  ): ProductTableRow {
    return {
      position: `${placeholder.positionId?.split(".")[0]}.${ALPHABET[index]}`,
      quantity: placements,
      name: placeholderProduct.name,
      id: placeholderProduct.id,
      productCosts: 0,
      totalCosts: 0,
      isAvailable: false,
      isPlaceholder: false,
    };
  }

  private generateProductTableRows(
    productData: { products: ProductData[]; occurrences: number }[],
    floorplanItems: FloorplanItem[],
  ): ProductTableRow[] {
    return productData
      .map((config) => {
        return config.products.map((product) => {
          let notesIndication = "";
          if (this.hasProductNotes(product, floorplanItems)) {
            notesIndication = "*";
          }
          return {
            position: product.position!,
            quantity: (product.fullType === FullProductType.ATTACHMENT_INSTRUCTIONS ? 1 : config.occurrences) + notesIndication,
            name: product.name,
            id: product.id!,
            productCosts: product.productCosts,
            totalCosts: config.occurrences * product.productCosts,
            isAvailable: product.isAvailable,
            isPlaceholder: false,
          };
        });
      })
      .flat(1);
  }

  private generateServicesTableRows(servicesData: ServiceData[], position: number): ServicesTableRow[] {
    return servicesData.map((serviceData: ServiceData, index: number) => {
      return {
        position: position + index,
        title: serviceData.title,
        description: serviceData.service.notes,
        id: serviceData.id,
        costs: serviceData.service.costs || 0,
      };
    });
  }

  private addFooter(pdf: jsPDF, container: Container) {
    const footerY = Math.min(
      ContainerProperties.Y + ContainerProperties.HEIGHT - ProductListProperties.FOOTER_HEIGHT,
      container.yPosition,
    );
    pdf.setFontSize(18 * PdfProperties.POINT_TO_PIXEL_RATIO);
    pdf.text(
      $localize`:@@pdfExport.productList.asteriskExplanation:* Produktnotizen werden gesondert aufgeführt`,
      container.xPosition + TableProperties.PADDING_X,
      footerY + 32,
      { baseline: "top" },
    );

    pdf.setFillColor(PdfProperties.FOOTER_COLOR);
    pdf.setDrawColor("#ffffff");
    pdf.rect(container.xPosition + TableProperties.PADDING_X, footerY + 66, TableProperties.ROW_SEPARATOR_LENGTH, 44, "F");

    const icon = new Image();
    icon.src = "assets/odx_info.png";
    pdf.addImage(icon, "png", container.xPosition + TableProperties.PADDING_X + 10, footerY + 78, 20, 20);

    pdf.setFontSize(13 * PdfProperties.POINT_TO_PIXEL_RATIO);
    pdf.text(
      $localize`:@@productList.footer:Die Produktliste ist kein verbindliches Angebot`,
      container.xPosition + TableProperties.PADDING_X + 40,
      footerY + 82,
      { baseline: "top" },
    );
    container.yPosition = ContainerProperties.HEIGHT;
  }

  private drawSummary(pdf: jsPDF, container: Container, servicesCost: number, overallProductCost: number) {
    container.yPosition += 64;
    pdf.setFontSize(ProductListProperties.SUMMARY_FONT_SIZE);
    this.drawSummaryRow(pdf, container, overallProductCost, $localize`:@@global.products:Produkte`);
    this.drawSummaryRow(pdf, container, servicesCost, $localize`:@@global.services:Dienstleistungen`);
    this.drawSummaryRow(pdf, container, overallProductCost + servicesCost, $localize`:@@productList.netSum:Nettosumme`);
  }

  private drawFloorplanSummary(pdf: jsPDF, container: Container, overallProductCost: number) {
    container.yPosition += 64;
    pdf.setFontSize(ProductListProperties.SUMMARY_FONT_SIZE);
    this.drawSummaryRow(pdf, container, overallProductCost, $localize`:@@productList.netSum:Nettosumme`);
  }

  private drawSummaryFooter(pdf: jsPDF, container: Container) {
    pdf.setFontSize(18 * PdfProperties.POINT_TO_PIXEL_RATIO);
    this.drawProductListHint(pdf, container);
  }

  private drawFloorplanSummaryFooter(pdf: jsPDF, container: Container) {
    pdf.setFontSize(18 * PdfProperties.POINT_TO_PIXEL_RATIO);
    pdf.text(
      $localize`:@@pdfExport.productList.asteriskExplanation:* Produktnotizen werden gesondert aufgeführt`,
      container.xPosition + 0.5 * ContainerProperties.WIDTH - 10,
      container.yPosition + 32,
      { baseline: "top" },
    );
    this.drawProductListHint(pdf, container);
  }

  private drawSummaryRow(pdf: jsPDF, container: Container, overallProductCost: number, label: string) {
    pdf.text(label, container.xPosition + 0.5 * ContainerProperties.WIDTH, container.yPosition, { baseline: "top" });
    pdf.text(
      formatCurrency(overallProductCost, this.localeId, "€"),
      container.xPosition + ContainerProperties.WIDTH - 36,
      container.yPosition,
      {
        align: "right",
        baseline: "top",
      },
    );
    container.yPosition += 32;
  }

  private drawProductListHint(pdf: jsPDF, container: Container) {
    pdf.setFillColor(PdfProperties.FOOTER_COLOR);
    pdf.setDrawColor("#ffffff");
    pdf.rect(
      container.xPosition + 0.5 * ContainerProperties.WIDTH - 10,
      container.yPosition + 66,
      0.5 * ContainerProperties.WIDTH - 16,
      44,
      "F",
    );

    const icon = new Image();
    icon.src = "assets/odx_info.png";
    pdf.addImage(icon, "png", container.xPosition + 0.5 * ContainerProperties.WIDTH, container.yPosition + 78, 20, 20);

    pdf.setFontSize(13 * PdfProperties.POINT_TO_PIXEL_RATIO);
    pdf.text(
      $localize`:@@productList.footer:Die Produktliste ist kein verbindliches Angebot`,
      container.xPosition + 0.5 * ContainerProperties.WIDTH + 30,
      container.yPosition + 82,
      { baseline: "top" },
    );
  }

  private drawProductTable(
    pdf: jsPDF,
    container: Container,
    tableOfContents: TableOfContents,
    headline: string,
    totalProductCost: number,
    productTable: ProductListTable,
    showCosts: boolean,
    floorplanName?: string,
  ) {
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    const isEnoughSpace = this.checkIfIsEnoughSpace(
      productTable.rows[0].textHeight + TableProperties.ROW_PADDING_Y + TableProperties.EMPTY_TABLE_HEIGHT,
      container,
    );
    const pageHeadline = floorplanName ? `${this.headlines.PRODUCT_LIST} | ${floorplanName}` : this.headlines.PRODUCT_LIST;
    if (!this.isFirstContainerDrawn) {
      this.containerService.addOnNewPage(pdf, container, pageHeadline, tableOfContents);
      this.isFirstContainerDrawn = true;
    } else if (!isEnoughSpace) {
      this.addFooter(pdf, container);
      this.containerService.add(pdf, container, pageHeadline, tableOfContents);
    }
    this.addProductHeadline(pdf, container, headline, totalProductCost, showCosts);
    this.addTableHeaders(pdf, container, productTable.columns);
    this.addRowSeparator(pdf, container, true);
    productTable.rows.forEach((row) => {
      const isEnoughSpace = this.checkIfIsEnoughSpace(row.textHeight + TableProperties.ROW_PADDING_Y, container);
      if (!isEnoughSpace) {
        this.addFooter(pdf, container);
        this.containerService.add(pdf, container, pageHeadline, tableOfContents);
        this.addProductHeadline(pdf, container, headline, totalProductCost, showCosts);
        this.addTableHeaders(pdf, container, productTable.columns);
        this.addRowSeparator(pdf, container, true);
      }
      this.addProductRow(pdf, container, productTable.columns, row, showCosts);
      this.addRowSeparator(pdf, container);
    });
    container.yPosition += TableProperties.GAP_BETWEEN_TABLES;
  }

  private checkIfIsEnoughSpace(height: number, container: Container) {
    return (
      ContainerProperties.Y + ContainerProperties.HEIGHT - ProductListProperties.FOOTER_HEIGHT - container.yPosition > height
    );
  }

  private drawTotalProductCosts(pdf: jsPDF, container: Container, costs: number) {
    pdf.text(
      formatCurrency(costs, this.localeId, "€"),
      container.xPosition + ContainerProperties.WIDTH - 36,
      container.yPosition,
      {
        baseline: "top",
        align: "right",
      },
    );
  }

  private addTableHeaders(pdf: jsPDF, container: Container, columns: TableColumn[]) {
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    columns.forEach((column, index) => {
      if (index < 4) {
        pdf.text(column.title, container.xPosition + column.xPosition, container.yPosition, { baseline: "top" });
      } else {
        pdf.text(column.title, container.xPosition + column.xPosition, container.yPosition, { baseline: "top", align: "right" });
      }
    });
    container.yPosition += TableProperties.TEXT_HEIGHT;
    container.yPosition += TableProperties.ROW_PADDING_BOTTOM;
  }

  private addProductRow(
    pdf: jsPDF,
    container: Container,
    columns: TableColumn[],
    row: ProductListProductRow | ProductListPlaceholderRow,
    showCosts: boolean,
  ) {
    container.yPosition += TableProperties.ROW_PADDING_TOP;
    if (row.isPlaceholder) {
      pdf.setTextColor(PdfProperties.ODX_RED);
    }
    if (!row.isAvailable) {
      pdf.setTextColor(PdfProperties.ODX_YELLOW);
      pdf.addImage(
        ImagePaths.WARNING_SIGN,
        "PNG",
        container.xPosition +
          columns[0].xPosition +
          pdf.getTextDimensions(row.position).w +
          0.5 * TableProperties.WARNING_ICON_SIZE,
        container.yPosition,
        TableProperties.WARNING_ICON_SIZE,
        TableProperties.WARNING_ICON_SIZE,
      );
    }
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    pdf.text(row.position, container.xPosition + columns[0].xPosition, container.yPosition, { baseline: "top" });
    pdf.text(row.quantity, container.xPosition + columns[1].xPosition, container.yPosition, { baseline: "top" });
    row.name.forEach((line, index) => {
      pdf.text(
        line,
        container.xPosition + columns[2].xPosition,
        container.yPosition + index * pdf.getTextDimensions(line).h * PdfProperties.POINT_TO_PIXEL_RATIO,
        { baseline: "top" },
      );
    });
    pdf.text(row.id, container.xPosition + columns[3].xPosition, container.yPosition, { baseline: "top" });
    if (showCosts) {
      pdf.text(row.productCosts, container.xPosition + columns[4].xPosition, container.yPosition, {
        baseline: "top",
        align: "right",
      });
      pdf.text(row.totalCosts, container.xPosition + columns[5].xPosition, container.yPosition, {
        baseline: "top",
        align: "right",
      });
    }
    pdf.setTextColor(PdfProperties.DRAEGERBLUE);
    container.yPosition += row.textHeight;
    container.yPosition += TableProperties.ROW_PADDING_BOTTOM;
  }

  private addServiceRow(
    pdf: jsPDF,
    container: Container,
    columns: TableColumn[],
    row: ProductListServiceRow,
    showCosts: boolean,
  ) {
    container.yPosition += TableProperties.ROW_PADDING_TOP;
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    pdf.text(row.position, container.xPosition + columns[0].xPosition, container.yPosition, { baseline: "top" });
    pdf.text(row.title, container.xPosition + columns[1].xPosition, container.yPosition, { baseline: "top" });
    row.description.forEach((line, index) => {
      pdf.text(
        line,
        container.xPosition + columns[2].xPosition,
        container.yPosition + index * pdf.getTextDimensions(line).h * PdfProperties.POINT_TO_PIXEL_RATIO,
        { baseline: "top" },
      );
    });
    pdf.text(row.id, container.xPosition + columns[3].xPosition, container.yPosition, { baseline: "top" });
    if (showCosts) {
      pdf.text(row.costs, container.xPosition + columns[4].xPosition, container.yPosition, {
        baseline: "top",
        align: "right",
      });
    }
    pdf.setTextColor(PdfProperties.DRAEGERBLUE);
    container.yPosition += row.textHeight;
    container.yPosition += TableProperties.ROW_PADDING_BOTTOM;
  }

  private hasProductNotes(productData: ProductData, floorplanItems: FloorplanItem[]) {
    const floorplanItemsWithNotes = floorplanItems.filter((item) => item.notes.length);
    if (!floorplanItemsWithNotes.length) return false;

    const floorplanItemProductIds = floorplanItems.map((floorplanItem) => {
      if (floorplanItem.notes.length) {
        if (floorplanItem instanceof FloorplanGasWarningCenter) {
          return this.gasWarningCenterConfigurations.find((config) => config.id === floorplanItem.config.id)?.productId;
        }
        if (floorplanItem instanceof FloorplanTransmitter) {
          return this.transmitterConfigurations.find((config) => config.id === floorplanItem.config.id)?.productId;
        }
        if (floorplanItem instanceof FloorplanAlarmDevice) {
          return this.alarmDeviceConfigurations.find((config) => config.id === floorplanItem.config.id)?.productIds[0];
        }
        if (floorplanItem instanceof FloorplanSignalElement) {
          return this.signalElementConfigurations.find((config) => config.id === floorplanItem.config.id)?.productId;
        }
        if (floorplanItem instanceof FloorplanPlasticSign) {
          return this.plasticSignConfigurations.find((config) => config.id === floorplanItem.config.id)?.productId;
        }
      }
      return undefined;
    });
    return floorplanItemProductIds.filter((productId) => {
      if (productId) {
        return productId === productData.id;
      }
      return false;
    }).length;
  }

  private hasPlaceholderNotes(floorplanItems: FloorplanItem[]) {
    return floorplanItems.some((item) => item.notes.length);
  }
}
