import { Checklist } from "@domain/project/checklist";
import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { PlasticSignConfiguration } from "@domain/project/configurations/plastic-sign-configuration";
import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { SignalElementConfiguration } from "@domain/project/configurations/signal-element-configuration";
import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";
import { ContactPerson } from "@domain/project/contact-person";
import { Customer } from "@domain/project/customer";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { GoodsRecipient } from "@domain/project/goods-recipient";
import { ProjectEventType } from "@domain/project/project-event";
import { ProjectImage } from "@domain/project/project-image";
import { ProjectRelatedService } from "@domain/project/project-related-service";
import { ProjectUpdatePublisher } from "@domain/project/project-update-publisher";
import { IsArrayOfInstancesOf, IsInstanceOf } from "@utils/class-validator/class-validator-constraints";
import { Exclude, Expose, Transform, Type, instanceToPlain, plainToInstance } from "class-transformer";
import { IsBoolean, IsDate, IsOptional, IsString, ValidateNested } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class Project {
  @IsString()
  @Expose({ name: "id" })
  private _id: string;

  @IsInstanceOf(Customer)
  @ValidateNested()
  @Type(() => Customer)
  @Expose({ name: "customer" })
  private readonly _customer: Customer = new Customer();

  @IsInstanceOf(ContactPerson)
  @ValidateNested()
  @Type(() => ContactPerson)
  @Expose({ name: "contactPerson" })
  private readonly _contactPerson: ContactPerson = new ContactPerson();

  @IsInstanceOf(GoodsRecipient)
  @ValidateNested()
  @Type(() => GoodsRecipient)
  @Expose({ name: "goodsRecipient" })
  @Transform((params) => params.value || new GoodsRecipient(), {
    toClassOnly: true,
  })
  private readonly _goodsRecipient: GoodsRecipient = new GoodsRecipient();

  @IsArrayOfInstancesOf(Floorplan)
  @ValidateNested()
  @Type(() => Floorplan)
  @Expose({ name: "floorplans" })
  private readonly _floorplans: Floorplan[] = [];

  @IsArrayOfInstancesOf(ProjectImage)
  @ValidateNested()
  @Type(() => ProjectImage)
  @Expose({ name: "images" })
  private readonly _images: ProjectImage[] = [];

  @IsArrayOfInstancesOf(AlarmDeviceConfiguration)
  @ValidateNested()
  @Type(() => AlarmDeviceConfiguration)
  @Expose({ name: "alarmDevices" })
  private readonly _alarmDevices: AlarmDeviceConfiguration[] = [];

  @IsArrayOfInstancesOf(TransmitterConfiguration)
  @ValidateNested()
  @Type(() => TransmitterConfiguration)
  @Expose({ name: "transmitters" })
  private readonly _transmitters: TransmitterConfiguration[] = [];

  @IsArrayOfInstancesOf(GasWarningCenterConfiguration)
  @ValidateNested()
  @Type(() => GasWarningCenterConfiguration)
  @Expose({ name: "gasWarningCenters" })
  private readonly _gasWarningCenters: GasWarningCenterConfiguration[] = [];

  @IsArrayOfInstancesOf(SignalElementConfiguration)
  @ValidateNested()
  @Type(() => SignalElementConfiguration)
  @Expose({ name: "signalElements" })
  private readonly _signalElements: SignalElementConfiguration[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(PlasticSignConfiguration)
  @Type(() => PlasticSignConfiguration)
  @Expose({ name: "plasticSigns" })
  private readonly _plasticSigns: PlasticSignConfiguration[] = [];

  @IsDate()
  @Type(() => Date)
  @Expose({ name: "lastModified" })
  private _lastModified: Date = new Date();

  @IsInstanceOf(Checklist)
  @ValidateNested()
  @Type(() => Checklist)
  @Expose({ name: "checklist" })
  private readonly _checklist: Checklist = new Checklist();

  @IsInstanceOf(ProjectRelatedService)
  @ValidateNested()
  @Type(() => ProjectRelatedService)
  @Expose({ name: "assembly" })
  private readonly _assembly: ProjectRelatedService = new ProjectRelatedService();

  @IsInstanceOf(ProjectRelatedService)
  @ValidateNested()
  @Type(() => ProjectRelatedService)
  @Expose({ name: "installation" })
  private readonly _installation: ProjectRelatedService = new ProjectRelatedService();

  @IsInstanceOf(ProjectRelatedService)
  @ValidateNested()
  @Type(() => ProjectRelatedService)
  @Expose({ name: "documentation" })
  private readonly _documentation: ProjectRelatedService = new ProjectRelatedService();

  @IsInstanceOf(ProjectRelatedService)
  @ValidateNested()
  @Type(() => ProjectRelatedService)
  @Expose({ name: "engineering" })
  private readonly _engineering: ProjectRelatedService = new ProjectRelatedService();

  @IsInstanceOf(ProjectRelatedService)
  @ValidateNested()
  @Type(() => ProjectRelatedService)
  @Expose({ name: "additionalServices" })
  private readonly _additionalServices: ProjectRelatedService = new ProjectRelatedService();

  @IsDate()
  @Type(() => Date)
  public lastCloudSync?: Date;

  @IsString()
  @IsOptional()
  public cloudId?: string;

  @IsOptional()
  @Exclude()
  private _updatePublisher?: ProjectUpdatePublisher;

  @IsBoolean()
  @Exclude()
  private floorplansInitialized = false;

  @IsString()
  public name: string;

  @IsString()
  @Transform((params) => params.value || "DE")
  public country: string;

  @IsString()
  @IsOptional()
  public forzaId?: string;

  @IsString()
  @IsOptional()
  public placeName?: string;

  @IsString()
  @IsOptional()
  public notes?: string;

  @IsString()
  @IsOptional()
  public crmEntryId?: string;

  @IsBoolean()
  public showCostFlag: boolean;

  constructor(
    id: string,
    name: string,
    country?: string,
    forzaId?: string,
    placeName?: string,
    notes?: string,
    crmEntryId?: string,
    showCostFlag: boolean = false,
  ) {
    this._id = id;
    this.name = name;
    this.country = country || "DE";
    this.forzaId = forzaId;
    this.placeName = placeName;
    this.notes = notes;
    this.crmEntryId = crmEntryId;
    this.showCostFlag = showCostFlag;
  }

  static create(name: string): Project {
    return new Project(uuidv4(), name);
  }

  @Exclude()
  set updatePublisher(updatePublisher: ProjectUpdatePublisher | undefined) {
    this._updatePublisher = updatePublisher;
  }

  get id(): string {
    return this._id;
  }

  get images(): readonly ProjectImage[] {
    this._images.forEach((image) => image.init(this));
    return this._images;
  }

  get alarmDevices(): readonly AlarmDeviceConfiguration[] {
    this._alarmDevices.forEach((alarmDevice) => alarmDevice.init(this));
    return this._alarmDevices;
  }

  get transmitters(): readonly TransmitterConfiguration[] {
    this._transmitters.forEach((transmitter) => transmitter.init(this));
    return this._transmitters;
  }

  get gasWarningCenters(): readonly GasWarningCenterConfiguration[] {
    this._gasWarningCenters.forEach((gasWarningCenter) => gasWarningCenter.init(this));
    return this._gasWarningCenters;
  }

  get signalElements(): readonly SignalElementConfiguration[] {
    this._signalElements.forEach((signalElement) => signalElement.init(this));
    return this._signalElements;
  }

  get plasticSigns(): readonly PlasticSignConfiguration[] {
    this._plasticSigns.forEach((plasticSign) => plasticSign.init(this));
    return this._plasticSigns;
  }

  get productConfigurations(): ProductConfiguration[] {
    return new Array<ProductConfiguration>().concat(
      this.alarmDevices,
      this.transmitters,
      this.gasWarningCenters,
      this.signalElements,
      this.plasticSigns,
    );
  }

  get customer(): Customer {
    return this._customer;
  }

  get contactPerson(): ContactPerson {
    return this._contactPerson;
  }

  get goodsRecipient(): GoodsRecipient {
    return this._goodsRecipient;
  }

  get lastModified(): Date {
    return this._lastModified;
  }

  set lastModified(date: Date) {
    this._lastModified = date;
  }

  get checklist(): Checklist {
    return this._checklist;
  }

  get assembly(): ProjectRelatedService {
    return this._assembly;
  }

  get installation(): ProjectRelatedService {
    return this._installation;
  }

  get documentation(): ProjectRelatedService {
    return this._documentation;
  }

  get engineering(): ProjectRelatedService {
    return this._engineering;
  }

  get additionalServices(): ProjectRelatedService {
    return this._additionalServices;
  }

  get floorplans(): Floorplan[] {
    if (!this.floorplansInitialized) {
      this._floorplans.forEach((floorplan) => floorplan.init(this));
      this.floorplansInitialized = true;
    }
    return this._floorplans;
  }

  get inSyncWithCloud(): boolean {
    return !!this.lastCloudSync && this.lastCloudSync >= this.lastModified;
  }

  validate(): void {
    // floorplan init makes sure that the floorplan is consistent with the project and raises an error if not
    this._floorplans.forEach((floorplan) => floorplan.init(this));
  }

  updateCloudSync(cloudId: string) {
    this.lastCloudSync = new Date();
    this.cloudId = cloudId;
    this.publishUpdate(ProjectEventType.SYNCED_TO_CLOUD, this);
  }

  addImages(...images: ProjectImage[]) {
    this._images.push(...images);
    images.forEach((image) => {
      image.init(this);
      this.publishUpdate(ProjectEventType.IMAGE_ADDED, image);
    });
  }

  getImageById(imageId: string): ProjectImage | undefined {
    return this.images.find((image) => image.id === imageId);
  }

  deleteImage(image: ProjectImage) {
    const index = this._images.indexOf(image);
    if (index === -1) {
      throw Error(`Image with id '${image.id}' is not contained in project with id '${this.id}' and can not be deleted`);
    }
    this.floorplans.forEach((floorplan) => floorplan.deleteImagesByProjectImage(image));
    this._images.splice(index, 1);
    this.publishUpdate(ProjectEventType.IMAGE_DELETED, image);
    this.setImagePositionIds();
  }

  countPlacedAlarmDevices(alarmDevice: AlarmDeviceConfiguration): number {
    let amount = 0;
    this.floorplans.forEach((floorplan) => (amount += floorplan.countPlacedAlarmDevices(alarmDevice)));
    return amount;
  }

  countPlacedTransmitters(transmitter: TransmitterConfiguration): number {
    let amount = 0;
    this.floorplans.forEach((floorplan) => (amount += floorplan.countPlacedTransmitters(transmitter)));
    return amount;
  }

  countPlacedGasWarningCenters(gasWarningCenter: GasWarningCenterConfiguration): number {
    let amount = 0;
    this.floorplans.forEach((floorplan) => (amount += floorplan.countPlacedGasWarningCenters(gasWarningCenter)));
    return amount;
  }

  countPlacedSignalElements(signalElement: SignalElementConfiguration): number {
    let amount = 0;
    this.floorplans.forEach((floorplan) => (amount += floorplan.countPlacedSignalElements(signalElement)));
    return amount;
  }

  countPlacedPlasticSigns(plasticSign: PlasticSignConfiguration): number {
    let amount = 0;
    this.floorplans.forEach((floorplan) => (amount += floorplan.countPlacedPlasticSigns(plasticSign)));
    return amount;
  }

  addAlarmDevices(...alarmDevices: AlarmDeviceConfiguration[]) {
    this._alarmDevices.push(...alarmDevices);
    alarmDevices.forEach((config) => this.publishUpdate(ProjectEventType.ALARM_DEVICE_CONFIGURATION_ADDED, config));
  }

  getAlarmDeviceById(alarmDeviceId: string): AlarmDeviceConfiguration | undefined {
    return this.alarmDevices.find((alarmDevice) => alarmDevice.id === alarmDeviceId);
  }

  deleteAlarmDevice(alarmDevice: AlarmDeviceConfiguration) {
    this.deleteConfiguration(
      alarmDevice,
      this._alarmDevices,
      ProjectEventType.ALARM_DEVICE_CONFIGURATION_DELETED,
      (floorplan, config) => floorplan.deleteAlarmDevicesByConfig(config),
    );
  }

  addTransmitters(...transmitters: TransmitterConfiguration[]) {
    this._transmitters.push(...transmitters);
    transmitters.forEach((config) => this.publishUpdate(ProjectEventType.TRANSMITTER_CONFIGURATION_ADDED, config));
  }

  getTransmitterById(transmitterId: string): TransmitterConfiguration | undefined {
    return this.transmitters.find((transmitter) => transmitter.id === transmitterId);
  }

  deleteTransmitter(transmitter: TransmitterConfiguration) {
    this.deleteConfiguration(
      transmitter,
      this._transmitters,
      ProjectEventType.TRANSMITTER_CONFIGURATION_DELETED,
      (floorplan, config) => floorplan.deleteTransmittersByConfig(config),
    );
  }

  addGasWarningCenters(...gasWarningCenters: GasWarningCenterConfiguration[]) {
    this._gasWarningCenters.push(...gasWarningCenters);
    gasWarningCenters.forEach((config) => this.publishUpdate(ProjectEventType.GAS_WARNING_CENTER_CONFIGURATION_ADDED, config));
  }

  getGasWarningCenterById(gasWarningCenterId: string): GasWarningCenterConfiguration | undefined {
    return this.gasWarningCenters.find((gasWarningCenter) => gasWarningCenter.id === gasWarningCenterId);
  }

  deleteGasWarningCenter(gasWarningCenter: GasWarningCenterConfiguration) {
    this.deleteConfiguration(
      gasWarningCenter,
      this._gasWarningCenters,
      ProjectEventType.GAS_WARNING_CENTER_CONFIGURATION_DELETED,
      (floorplan, config) => floorplan.deleteGasWarningCentersByConfig(config),
    );
  }

  addSignalElements(...signalElements: SignalElementConfiguration[]) {
    this._signalElements.push(...signalElements);
    signalElements.forEach((config) => this.publishUpdate(ProjectEventType.SIGNAL_ELEMENT_CONFIGURATION_ADDED, config));
  }

  getSignalElementById(signalElementId: string): SignalElementConfiguration | undefined {
    return this.signalElements.find((signalElement) => signalElement.id === signalElementId);
  }

  deleteSignalElement(signalElement: SignalElementConfiguration) {
    this.deleteConfiguration(
      signalElement,
      this._signalElements,
      ProjectEventType.SIGNAL_ELEMENT_CONFIGURATION_DELETED,
      (floorplan, config) => floorplan.deleteSignalElementsByConfig(config),
    );
  }

  addPlasticSigns(...plasticSigns: PlasticSignConfiguration[]) {
    this._plasticSigns.push(...plasticSigns);
    plasticSigns.forEach((config) => this.publishUpdate(ProjectEventType.PLASTIC_SIGN_CONFIGURATION_ADDED, config));
  }

  getPlasticSignById(plasticSignId: string): PlasticSignConfiguration | undefined {
    return this.plasticSigns.find((plasticSign) => plasticSign.id === plasticSignId);
  }

  deletePlasticSign(plasticSign: PlasticSignConfiguration) {
    this.deleteConfiguration(
      plasticSign,
      this._plasticSigns,
      ProjectEventType.PLASTIC_SIGN_CONFIGURATION_DELETED,
      (floorplan, config) => floorplan.deletePlasticSignsByConfig(config),
    );
  }

  addFloorplan(name: string, fileUrl?: string): Floorplan {
    const floorplan = Floorplan.create(this, name, fileUrl);
    floorplan.init(this);
    this.floorplans.push(floorplan);
    this.publishUpdate(ProjectEventType.FLOORPLAN_ADDED, floorplan);
    return floorplan;
  }

  getFloorplanById(floorplanId: string) {
    return this.floorplans.find((floorplan) => floorplan.id === floorplanId);
  }

  deleteFloorplan(floorplan: Floorplan) {
    const index = this.floorplans.indexOf(floorplan);
    if (index > -1) {
      this.floorplans.splice(index, 1);
    }
    this.setAllPositionIds();
    this.publishUpdate(ProjectEventType.FLOORPLAN_DELETED, floorplan);
  }

  copy(): Project {
    const copy = plainToInstance(Project, instanceToPlain(this));
    copy._id = uuidv4();
    copy.name = `${this.name} (2)`;
    copy._lastModified = new Date();
    copy.lastCloudSync = undefined;
    copy.cloudId = undefined;
    return copy;
  }

  setPositionNumbers() {
    let startingPositionNumber = 1;

    this.setPositionNumber(startingPositionNumber, this._gasWarningCenters, (floorplan, config) => {
      floorplan.updateGasWarningCentersPositionIds(config);
    });
    startingPositionNumber += this.countPlacedConfigurations(this._gasWarningCenters);

    this.setGasWarningCenterPlaceholderPositionIds(startingPositionNumber);
    startingPositionNumber += this.countPlaceholdersWithProductId(
      (floorplan: Floorplan) => floorplan.gasWarningCenterPlaceholders,
    );

    this.setPositionNumber(startingPositionNumber, this._transmitters, (floorplan, config) => {
      floorplan.updateTransmittersPositionIds(config);
    });
    startingPositionNumber += this.countPlacedConfigurations(this._transmitters);

    this.setTransmitterPlaceholderPositionIds(startingPositionNumber);
    startingPositionNumber += this.countPlaceholdersWithProductId((floorplan: Floorplan) => floorplan.transmitterPlaceholders);

    this.setPositionNumber(startingPositionNumber, this._alarmDevices, (floorplan, config) => {
      floorplan.updateAlarmDevicesPositionIds(config);
    });
    startingPositionNumber += this.countPlacedConfigurations(this._alarmDevices);

    this.setAlarmDevicePlaceholderPositionIds(startingPositionNumber);
    startingPositionNumber += this.countPlaceholdersWithProductId((floorplan: Floorplan) => floorplan.alarmDevicePlaceholders);

    this.setPositionNumber(startingPositionNumber, this._signalElements, (floorplan, config) => {
      floorplan.updateSignalElementsPositionIds(config);
    });
    startingPositionNumber += this.countPlacedConfigurations(this._signalElements);

    this.setSignalElementPlaceholderPositionIds(startingPositionNumber);
    startingPositionNumber += this.countPlaceholdersWithProductId((floorplan: Floorplan) => floorplan.signalElementPlaceholders);

    this.setPositionNumber(startingPositionNumber, this._plasticSigns, (floorplan, config) => {
      floorplan.updatePlasticSignsPositionIds(config);
    });
    startingPositionNumber += this.countPlacedConfigurations(this._plasticSigns);

    this.setPlasticSignPlaceholderPositionIds(startingPositionNumber);
  }

  setGasWarningCenterPlaceholderPositionIds(positionNumber?: number) {
    this.setPlaceholderPositionIds((floorplan: Floorplan) => floorplan.gasWarningCenterPlaceholders, "PG.", positionNumber);
  }

  setTransmitterPlaceholderPositionIds(positionNumber?: number) {
    this.setPlaceholderPositionIds((floorplan: Floorplan) => floorplan.transmitterPlaceholders, "PT.", positionNumber);
  }

  setSignalElementPlaceholderPositionIds(positionNumber?: number) {
    this.setPlaceholderPositionIds((floorplan: Floorplan) => floorplan.signalElementPlaceholders, "PL.", positionNumber);
  }

  setPlasticSignPlaceholderPositionIds(positionNumber?: number) {
    this.setPlaceholderPositionIds((floorplan: Floorplan) => floorplan.plasticSignPlaceholders, "PK.", positionNumber);
  }

  setAlarmDevicePlaceholderPositionIds(positionNumber?: number) {
    this.setPlaceholderPositionIds((floorplan: Floorplan) => floorplan.alarmDevicePlaceholders, "PA.", positionNumber);
  }

  setExZonePositionIds() {
    let indexCount0 = 0;
    let indexCount1 = 0;
    let indexCount2 = 0;
    this.floorplans.map((floorplan) => {
      floorplan.exZones.map((exZone) => {
        switch (exZone.exZoneType) {
          case "ZONE_0":
            exZone.positionId = "EX0." + (indexCount0 += 1);
            break;
          case "ZONE_1":
            exZone.positionId = "EX1." + (indexCount1 += 1);
            break;
          case "ZONE_2":
            exZone.positionId = "EX2." + (indexCount2 += 1);
        }
      });
    });
  }

  setDangerAreaPositionIds() {
    let indexCount = 0;
    this.floorplans.map((floorplan) => {
      floorplan.dangerAreas.map((dangerArea) => (dangerArea.positionId = "GB." + (indexCount += 1)));
    });
  }

  setImagePositionIds() {
    let indexCount = 0;
    this.floorplans.map((floorplan) => {
      floorplan.images.map((image) => {
        image.positionId = "F." + (indexCount += 1);
      });
    });
  }

  setPipelinePositionIds() {
    let indexCount = 0;
    this.floorplans.map((floorplan) => {
      floorplan.pipelines.map((pipeline) => (pipeline.positionId = "r." + (indexCount += 1)));
    });
  }

  setAirPathPositionIds() {
    let indexCountSupplyAir = 0;
    let indexCountExhaustAir = 0;
    this.floorplans.map((floorplan) => {
      floorplan.airPaths.map((airPath) => {
        airPath.direction === "SUPPLY_AIR"
          ? (airPath.positionId = "zu." + (indexCountSupplyAir += 1))
          : (airPath.positionId = "ab." + (indexCountExhaustAir += 1));
      });
    });
  }

  setFloorplanTextPositionIds() {
    let indexCount = 0;
    this.floorplans.map((floorplan) => {
      floorplan.floorplanTexts.map((floorplanText) => (floorplanText.positionId = (indexCount += 1).toString()));
    });
  }

  setMeasurementLinePositionIds() {
    let indexCount = 0;
    this.floorplans.map((floorplan) => {
      floorplan.measurementLines.map((measurementLine) => (measurementLine.positionId = "b." + (indexCount += 1)));
    });
  }

  setAllPositionIds() {
    this.setPositionNumbers();
    this.setExZonePositionIds();
    this.setDangerAreaPositionIds();
    this.setImagePositionIds();
    this.setPipelinePositionIds();
    this.setAirPathPositionIds();
    this.setFloorplanTextPositionIds();
    this.setMeasurementLinePositionIds();
  }

  publishUpdate(type: ProjectEventType, subject: any) {
    this.lastModified = new Date();
    if (this._updatePublisher) {
      this._updatePublisher.publishProjectEvent(type, subject);
    }
  }

  publishFloorplanUpdate(floorplan: Floorplan, type: FloorplanEventType, subject: any) {
    this.lastModified = new Date();
    if (this._updatePublisher) {
      this._updatePublisher.publishFloorplanEvent(floorplan, type, subject);
    }
  }

  private deleteConfiguration<T extends ProductConfiguration>(
    configurationToDelete: T,
    existingConfigurations: T[],
    deleteEventType: ProjectEventType,
    deleteFromFloorplanFn: (floorplan: Floorplan, config: T) => void,
  ) {
    const indexToDelete = existingConfigurations.indexOf(configurationToDelete);
    if (indexToDelete < 0) {
      return;
    }

    this.floorplans.forEach((floorplan) => {
      deleteFromFloorplanFn(floorplan, configurationToDelete);
    });

    existingConfigurations.splice(indexToDelete, 1);
    this.publishUpdate(deleteEventType, configurationToDelete);
  }

  private setPositionNumber<T extends ProductConfiguration>(
    positionNumber: number,
    configs: T[],
    updatePositionIdsFn: (floorplan: Floorplan, config: T) => void,
  ) {
    configs.forEach((config) => {
      if (config.countPlacedProducts(this) > 0) {
        config.positionNumber = positionNumber.toString();
        this.floorplans.forEach((floorplan) => {
          updatePositionIdsFn(floorplan, config);
        });
        positionNumber += 1;
      }
    });
  }

  private countPlacedConfigurations<T extends ProductConfiguration>(configs: T[]) {
    return configs.filter((config) => config.countPlacedProducts() > 0).length;
  }

  private countPlaceholdersWithProductId<T extends FloorplanPlaceholder | FloorplanTransmitterPlaceholder>(
    floorplanPlaceholdersCallback: (floorplan: Floorplan) => T[],
  ): number {
    return this.floorplans
      .flatMap((floorplan: Floorplan) => floorplanPlaceholdersCallback(floorplan))
      .filter(
        (placeholder: T) =>
          placeholder.products.length > 0 && placeholder.products.filter((placeholderProduct) => placeholderProduct.id).length,
      ).length;
  }

  private setPlaceholderPositionIds<T extends FloorplanPlaceholder | FloorplanTransmitterPlaceholder>(
    floorplanPlaceholdersCallback: (floorplan: Floorplan) => T[],
    positionIdPrefix: string,
    positionNumber?: number,
  ) {
    let indexCount = 0;
    this.floorplans.map((floorplan) => {
      floorplanPlaceholdersCallback(floorplan)
        .filter((placeholder: T) => !placeholder.products.filter((placeholderProduct) => placeholderProduct.id).length)
        .map((placeholder) => {
          placeholder.positionId = positionIdPrefix + (indexCount += 1);
        });
      if (positionNumber === undefined) {
        return;
      }
      floorplanPlaceholdersCallback(floorplan)
        .filter((placeholder: T) => placeholder.products.filter((placeholderProduct) => placeholderProduct.id).length)
        .map((placeholder) => {
          placeholder.positionId = positionNumber!.toString() + ".1";
          positionNumber!++;
        });
    });
  }
}
