import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ModalRef } from "@odx/angular/components/modal";
import { Subject } from "rxjs";

@Component({
  selector: "app-camera-modal",
  templateUrl: "./camera-modal.component.html",
  styleUrls: ["./camera-modal.component.scss"],
  standalone: true,
  imports: [UiKitModule],
})
export class CameraModalComponent implements OnInit, OnDestroy {
  @Input("$implicit")
  public readonly modalRef = inject(ModalRef<String, Blob>);

  isLoading$ = new Subject<boolean>();

  private imageCapture!: ImageCapture;
  private videoSettingsFront = {
    video: {
      width: {
        min: 1280,
        ideal: 1920,
        max: 2560,
      },
      height: {
        min: 720,
        ideal: 1080,
        max: 1440,
      },
      facingMode: "user",
    },
  };
  private videoSettingsBack = {
    video: {
      width: {
        min: 1280,
        ideal: 1920,
        max: 2560,
      },
      height: {
        min: 720,
        ideal: 1080,
        max: 1440,
      },
      facingMode: "environment",
    },
  };

  private videoTrack!: MediaStreamTrack;
  private isFacingEnv = true;

  ngOnInit(): void {
    this.startCamera(this.videoSettingsBack);
  }

  ngOnDestroy(): void {
    this.videoTrack.stop();
    this.isLoading$.complete();
  }

  takePhoto() {
    if (this.imageCapture == undefined) return;
    this.isLoading$.next(true);
    this.imageCapture
      .takePhoto()
      .then((image: Blob) => {
        this.isLoading$.next(false);
        this.modalRef.close(image);
      })
      .catch((error) => console.error(error));
  }

  switchCamera() {
    if (this.videoTrack.getCapabilities().facingMode!.length > 0) {
      const facingMode = this.videoTrack.getCapabilities().facingMode![0];
      if (facingMode == "environment") {
        if (!this.isFacingEnv) return;
        this.startCamera(this.videoSettingsFront);
        this.isFacingEnv = !this.isFacingEnv;
        return;
      }
      if (facingMode == "user") {
        if (this.isFacingEnv) return;
        this.startCamera(this.videoSettingsBack);
        this.isFacingEnv = !this.isFacingEnv;
        return;
      }
    }
    if (this.isFacingEnv) {
      this.startCamera(this.videoSettingsFront);
    } else {
      this.startCamera(this.videoSettingsBack);
    }

    this.isFacingEnv = !this.isFacingEnv;
  }

  startCamera(videoSettings: any) {
    let video: HTMLVideoElement = document.querySelector("#video")!;
    if ("mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia(videoSettings)
        .then((mediaStream) => {
          video.srcObject = mediaStream;
          this.videoTrack = mediaStream.getVideoTracks()[0];
          this.imageCapture = new ImageCapture(this.videoTrack);
        })
        .catch((error) => {
          this.modalRef.dismiss();
          console.error(error);
        });
    }
  }
}
