import { MeasurementLine } from "@domain/project/floorplan/measurement-line";
import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import { PositionIdTextKonva } from "@project/floorplanner/konva/position-id-text-konva";
import { Group } from "konva/lib/Group";
import { Arrow } from "konva/lib/shapes/Arrow";
import { Line } from "konva/lib/shapes/Line";
import { Rect } from "konva/lib/shapes/Rect";
import { Text } from "konva/lib/shapes/Text";

export class MeasurementLineKonva {
  static init(measurementLine: MeasurementLine): Group {
    let line = new Group({
      x: measurementLine.x,
      y: measurementLine.y,
      rotation: measurementLine.rotation,
      height: measurementLine.height,
      width: measurementLine.width,
      text: measurementLine.text,
      draggable: !measurementLine.locked,
    });
    let rect = new Rect({
      x: 0,
      y: -measurementLine.height / 2,
      width: measurementLine.width,
      height: measurementLine.height,
    });
    let arrow = new Arrow({
      points: [0, 0, measurementLine.width, 0],
      pointerLength: 10,
      pointerWidth: 10,
      fill: "black",
      stroke: "black",
      strokeWidth: 2,
      pointerAtBeginning: true,
    });
    let lineLeft = new Line({
      points: [-2, -measurementLine.height / 2, -2, measurementLine.height / 2],
      stroke: "black",
      strokeWidth: 2,
    });
    let lineRight = new Line({
      points: [measurementLine.width + 2, -measurementLine.height / 2, measurementLine.width + 2, measurementLine.height / 2],
      stroke: "black",
      strokeWidth: 2,
    });
    let text = new Text({
      text: measurementLine.text,
      y: -measurementLine.height / 2,
      fontSize: 15,
    });
    let positionId = PositionIdTextKonva.init(measurementLine);
    positionId.fontSize(15);

    text.x((measurementLine.width - text.width()) / 2);
    positionId.x((measurementLine.width + text.width() + 25) / 2);
    positionId.y(-measurementLine.height / 2);

    line.add(text);
    line.add(positionId);
    line.add(rect);
    line.add(arrow);
    line.add(lineLeft);
    line.add(lineRight);

    line.on("transform", () => {
      line.width(Math.max(5, line.width() * line.scaleX()));
      line.height(Math.max(5, line.height() * line.scaleY()));

      rect.y(-line.height() / 2);
      rect.height(line.height());
      rect.width(line.width());

      arrow.points([0, 0, line.width(), 0]);

      lineLeft.points([-2, -line.height() / 2, -2, line.height() / 2]);

      lineRight.points([line.width() + 2, -line.height() / 2, line.width() + 2, line.height() / 2]);

      text.x((line.width() - text.width()) / 2);
      positionId.x((line.width() + text.width() + 25) / 2);
      line.scaleX(1);
      line.scaleY(1);
    });

    line.on("transformend", () => measurementLine.updateForm(line.width(), line.height(), line.rotation(), line.x(), line.y()));

    line.setAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY, measurementLine);
    return line;
  }

  static refreshText(measurementLine: MeasurementLine, group: Group) {
    const text = group.getChildren()[0] as Text;
    const positionId = group.getChildren()[1] as Text;
    text.text(measurementLine.text);
    text.x((measurementLine.width - text.width()) / 2);
    this.setTextPosition(positionId, text, group);
  }

  static refreshPositionId(measurementLine: MeasurementLine, group: Group) {
    const text = group.getChildren()[0] as Text;
    const positionId = group.getChildren()[1] as Text;
    positionId.text(measurementLine.positionId!);
    this.setTextPosition(positionId, text, group);
  }

  private static setTextPosition(positionId: Text, text: Text, group: Group) {
    positionId.x((group.width() + text.width() + 25) / 2);
  }
}
