import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { Container } from "@pdf/helpers/container/container";
import { DraegerFonts } from "@pdf/helpers/draeger-fonts";
import { ContainerProperties, FontNames, Headlines, ImagePaths, PdfProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";

@Injectable({
  providedIn: "root",
})
export class LegendPagePdfService {
  private headlines = new Headlines();

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  generate(pdf: jsPDF, container?: Container) {
    if (container) {
      container.toggleActiveSide(pdf);
    }
    this.addFonts(pdf);
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setTextColor(PdfProperties.DRAEGERBLUE);
    pdf.setFontSize(PdfProperties.HEADLINE_FONT_SIZE);
    pdf.text(this.headlines.LEGEND, container?.xPosition || ContainerProperties.LEFT_X, PdfProperties.HEADLINE_Y, {
      baseline: "top",
    });
    const [width, height] = this.fitImageToFrame(987, 818, ContainerProperties.WIDTH, ContainerProperties.HEIGHT);
    const imagePath: ImagePaths = this.localeId === "de-DE" ? ImagePaths.LEGEND_DE : ImagePaths.LEGEND_EN;
    pdf.addImage(imagePath, "PNG", container?.xPosition || ContainerProperties.LEFT_X, ContainerProperties.Y, width, height);
  }

  private addFonts(doc: jsPDF) {
    new DraegerFonts().fonts.forEach((font) => {
      doc.addFileToVFS(font.ttfName, font.base64);
      doc.addFont(font.ttfName, font.fontName, font.fontWeight);
    });
  }

  private fitImageToFrame(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) {
    const ratio: number = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return [srcWidth * ratio, srcHeight * ratio];
  }
}
