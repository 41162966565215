import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { AttachmentSelectorComponent } from "@components/attachment-selector/attachment-selector.component";
import { ConfigurationWizardBackButtonComponent } from "@components/configuration-wizard-back-button/configuration-wizard-back-button.component";
import { ProductConfigStepperBarComponent } from "@components/stepper/product-config-stepper-bar/product-config-stepper-bar.component";
import { LocalizeProductNamePipe, Product } from "@domain/product/product";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { Project } from "@domain/project/project";
import { WizardComponent } from "@odx/angular/components/wizard";
import { FloorplanMenuType } from "@project/floorplanner/floorplan-menu/floorplan-menu-type";
import { GasWarningCenterSelectorComponent } from "@project/gas-warning-center-configuration/gas-warning-center-selector/gas-warning-center-selector.component";
import { GasWarningCenterType } from "@project/gas-warning-center-configuration/gas-warning-center-type";
import { GasWarningCenterTypeSelectorComponent } from "@project/gas-warning-center-configuration/gas-warning-center-type-selector/gas-warning-center-type-selector.component";
import { OriginalGasWarningCenterConfiguration } from "@project/gas-warning-center-configuration/original-gas-warning-center-configuration";
import { OriginalProductConfigurationFactory } from "@project/original-product-configuration-factory";

@Component({
  selector: "app-gas-warning-center-configuration",
  templateUrl: "./gas-warning-center-configuration.component.html",
  styleUrls: ["./gas-warning-center-configuration.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    ConfigurationWizardBackButtonComponent,
    GasWarningCenterTypeSelectorComponent,
    GasWarningCenterSelectorComponent,
    AttachmentSelectorComponent,
    ProductConfigStepperBarComponent,
    LocalizeProductNamePipe,
  ],
  providers: [OriginalProductConfigurationFactory, LocalizeProductNamePipe],
})
export class GasWarningCenterConfigurationComponent implements OnInit {
  protected readonly FloorplanMenuType = FloorplanMenuType;

  protected project!: Project;
  protected selectedGasWarningCenterTypeForm!: FormGroup;
  protected selectedType?: GasWarningCenterType;
  protected selectedGasWarningCenterForm!: FormGroup;
  protected selectedGasWarningCenter?: Product;
  protected selectedAttachments?: Product[];
  protected steps = 3;
  protected activeStep = 0;
  protected originalConfiguration?: OriginalGasWarningCenterConfiguration;

  @ViewChild("configurationWizard")
  private configurationWizard!: WizardComponent;

  @ViewChild("backButton")
  private backButton!: ConfigurationWizardBackButtonComponent;

  constructor(
    private activeRoute: ActivatedRoute,
    private productConfigurationService: OriginalProductConfigurationFactory,
    private localizeProductNamePipe: LocalizeProductNamePipe,
  ) {}

  ngOnInit(): void {
    this.project = this.activeRoute.parent!.snapshot.data["project"];
    if (this.activeRoute.snapshot.queryParams["config_id"]) {
      this.productConfigurationService
        .createOriginalGasWarningCenterConfiguration(this.project, this.activeRoute.snapshot.queryParams["config_id"])
        .subscribe((originalConfiguration) => {
          this.originalConfiguration = originalConfiguration;
        });
    }
  }

  onSubmit() {
    if (this.activeStep === 0) {
      this.selectedGasWarningCenterTypeForm.markAllAsTouched();
      if (!this.selectedGasWarningCenterTypeForm.valid) return;
    } else if (this.activeStep === 1) {
      this.selectedGasWarningCenterForm.markAllAsTouched();
      if (!this.selectedGasWarningCenterForm.valid) return;
    } else if (this.activeStep === 2) {
      this.originalConfiguration
        ? this.updateGasWarningCenterConfig(this.originalConfiguration.productConfiguration!)
        : this.createNewGasWarningCenterConfig();
    }
    this.configurationWizard.setActiveStepValid();
    this.configurationWizard.nextStep();
  }

  protected selectGasWarningCenter(gasWarningCenter?: Product) {
    this.selectedGasWarningCenter = gasWarningCenter;
  }

  private createNewGasWarningCenterConfig() {
    const gasWarningCenterConfig = GasWarningCenterConfiguration.create(
      this.localizeProductNamePipe.transform(this.selectedGasWarningCenter!),
      this.project,
      this.selectedGasWarningCenter!.id,
      this.selectedAttachments!.map((attachment) => attachment.id),
    );
    this.project.addGasWarningCenters(gasWarningCenterConfig);
    this.backButton.navigateBack();
  }

  private updateGasWarningCenterConfig(originalConfig: GasWarningCenterConfiguration) {
    const attachmentIds = this.selectedAttachments!.map((attachment) => attachment.id);
    originalConfig.update(this.selectedGasWarningCenter!, attachmentIds);
    this.backButton.navigateBack();
  }
}
