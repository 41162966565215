import { Injectable } from "@angular/core";
import { GasService } from "@domain/gas/gas.service";
import { LastProductUpdateService } from "@domain/product/last-product-update.service";
import { ProductService } from "@domain/product/product.service";
import { ToastHelper } from "@utils/toast-helper.service";
import { catchError, EMPTY, forkJoin, interval, map, Observable, ReplaySubject } from "rxjs";

export enum MasterDataInitStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

@Injectable({
  providedIn: "root",
})
export class MasterDataService {
  private static readonly UPDATE_CHECK_INTERVAL_MILLIS = 30 * 60 * 1000; // 30 minutes
  private static readonly UPDATE_INTERVAL_MILLIS = 7 * 24 * 60 * 60 * 1000; // 1 week

  private initStatus = new ReplaySubject<MasterDataInitStatus>(1);

  constructor(
    private productService: ProductService,
    private gasService: GasService,
    private toastHelper: ToastHelper,
    private productUpdateService: LastProductUpdateService,
  ) {
    this.init().subscribe();
    interval(MasterDataService.UPDATE_CHECK_INTERVAL_MILLIS).subscribe(() => {
      this.updateProductDataIfNecessary();
    });
  }

  refresh(): void {
    this.toastHelper.message($localize`:@@toast.refreshProductDataStarted:Aktualisierung der Produktdaten wurde gestartet`);

    forkJoin([this.productService.refreshAll(), this.gasService.refreshAll()]).subscribe({
      next: () =>
        this.toastHelper.success($localize`:@@toast.refreshProductDataSuccessful:Produktdaten wurden erfolgreich aktualisiert`),
      error: (e) => {
        console.error(e);
        this.toastHelper.errorOffline();
      },
    });
  }

  private init(): Observable<void> {
    this.initStatus.next(MasterDataInitStatus.IN_PROGRESS);
    return forkJoin([this.gasService.loadAll(), this.productService.loadAll()]).pipe(
      map((results) => {
        this.initStatus.next(MasterDataInitStatus.SUCCESS);
      }),
      catchError((e) => {
        console.error(e);
        this.initStatus.next(MasterDataInitStatus.ERROR);
        return EMPTY;
      }),
    );
  }

  get initStatus$(): Observable<MasterDataInitStatus> {
    return this.initStatus.asObservable();
  }

  private updateProductDataIfNecessary() {
    this.productUpdateService.getLastUpdated().subscribe((lastUpdate) => {
      const now = new Date().getTime();

      if (!navigator.onLine) {
        console.debug("Product data could not be updated because client is offline");
        return;
      }

      if (!lastUpdate || new Date(lastUpdate.date).getTime() < now - MasterDataService.UPDATE_INTERVAL_MILLIS) {
        this.refresh();
      }
    });
  }
}
