import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { DeleteButtonComponent } from "@components/delete-button/delete-button.component";
import { DuplicateButtonComponent } from "@components/duplicate-button/duplicate-button.component";
import { StandardImageComponent } from "@components/standard-image/standard-image.component";
import { ToggleLockButtonComponent } from "@components/toggle-lock-button/toggle-lock-button.component";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanService } from "@project/floorplanner/floorplan.service";
import { LocalizeDatePipe } from "src/locale/localize-date";
import { LocalizeTimePipe } from "src/locale/localize-time";

@Component({
  selector: "app-floorplan-item-details",
  templateUrl: "./floorplan-item-details.component.html",
  styleUrl: "./floorplan-item-details.component.scss",
  standalone: true,
  imports: [
    UiKitModule,
    ReactiveFormsModule,
    StandardImageComponent,
    ToggleLockButtonComponent,
    DeleteButtonComponent,
    DuplicateButtonComponent,
    LocalizeDatePipe,
    LocalizeTimePipe,
  ],
})
export class FloorplanItemDetailsComponent implements OnInit {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) floorplanItem!: FloorplanItem;
  @Input() showWarning: boolean = false;
  @Input() duplicateEnabled = true;
  @Input() imageUrl?: string;
  @Input() fallbackImageUrl?: string;

  protected notesForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private floorplanService: FloorplanService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  close() {
    this.floorplanService.deselectItem();
  }

  delete() {
    this.floorplanService.deleteSelectedItem();
  }

  duplicate() {
    this.floorplanService.duplicateSelectedItem();
  }

  private initForm() {
    this.notesForm = this.formBuilder.group({
      notes: [this.floorplanItem.notes, Validators.maxLength(2000)],
    });

    this.notesForm.valueChanges.subscribe(() => {
      if (!this.notesForm.valid || this.notesForm.pristine) return;
      this.update();
    });
  }

  private update() {
    this.floorplanItem.notes = this.notesForm.value.notes;
  }
}
