<div odxLayout="flex gap-none" id="project-information" class="flex-column h-100 overflow-hidden">
  <odx-area-header>
    <h5 class="odx-title-5" i18n="@@projectInformation.header" data-testid="projectInformation.header">Projektinformationen</h5>
    <odx-area-header-content>
      <form [formGroup]="switchForm" class="switch">
        <odx-switch
          formControlName="switch"
          #switch
          i18n="@@projectInformation.switch.showPrices.label"
          data-testid="projectInformation.switch.showCost"
          >Preise anzeigen</odx-switch
        >
      </form>
      <odx-action-group>
        <button odxButton (click)="onEdit()">
          <odx-icon name="edit" alignleft></odx-icon>
          <span i18n="@@global.edit">Bearbeiten</span>
        </button>
      </odx-action-group>
    </odx-area-header-content>
  </odx-area-header>
  <div odxlayout="flex horizontal-end">
    <button odxButton variant="primary" *ngIf="editEnabled" class="button-right" (click)="onSubmit()" i18n="@@global.save">
      Speichern
    </button>
  </div>
  <div odxLayout="gap-none" class="h-100 scroll">
    <div odxLayout="grid 4@tablet 12@phone">
      <app-project-form [isReadonly]="!editEnabled" #projectFormComponent></app-project-form>
      <div>
        <app-customer-form [isReadonly]="!editEnabled" #customerFormComponent></app-customer-form>
        <app-goods-recipient-form
          [isReadonly]="!editEnabled"
          (goodsRecipientFormEmitter$)="goodsRecipientForm = $event"
        ></app-goods-recipient-form>
      </div>
      <app-contact-person-form [isReadonly]="!editEnabled" #contactPersonFormComponent></app-contact-person-form>
    </div>
  </div>
</div>
