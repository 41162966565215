import { Component, inject, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { Modal, ModalRef } from "@odx/angular/components/modal";

@Component({
  templateUrl: "./file-modal.component.html",
  styleUrls: ["./file-modal.component.scss"],
  standalone: true,
  imports: [UiKitModule],
})
export class FileModalComponent implements Modal<any, String>, OnInit {
  public readonly modalRef = inject(ModalRef<any, string>);

  fileName!: string;
  fileUrl!: string;

  ngOnInit(): void {
    this.fileName = this.modalRef.data.fileName;
    this.fileUrl = this.modalRef.data.fileUrl;
  }
}
