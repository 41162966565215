import { Injectable } from "@angular/core";
import { fileToArrayBuffer } from "@utils/file-to-array-buffer";
import * as pdfjsLib from "pdfjs-dist";

@Injectable({
  providedIn: "root",
})
export class PdfToImageService {
  async convert(file: any, viewport: any) {
    let buffer = await fileToArrayBuffer(file);
    // @ts-ignore
    const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.entry");
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    let pdf;
    let { promise: loadingTask } = pdfjsLib.getDocument({
      data: buffer as string,
    });

    try {
      pdf = await loadingTask;
    } catch (e) {
      console.error("Invalid PDF", "The uploaded PDF could not be processed.");
      return;
    }

    let page = await pdf.getPage(1);

    viewport = page.getViewport({ scale: window.devicePixelRatio });

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context as Object,
      viewport: viewport,
    };

    const { promise } = page.render(renderContext);
    await promise;
    return canvas as HTMLCanvasElement;
  }
}
