<div odxLayout="flex gap-none" class="flex-column">
  <odx-header id="header" odxLayout="12">
    <odx-header-title>
      <div odxLayout="flex vertical-center">
        <a href="#" odxLayout="flex vertical-center" data-testid="navintaLogo">
          <img src="assets/navinta_logo.svg" alt="navinta logo" />
        </a>
        <a href="#"> Navinta</a>
      </div>
    </odx-header-title>
    <odx-action-group>
      <button odxButton [odxMenu]="localesMenu" data-testid="btn.localesMenu" #localesMenuButton>
        <odx-icon name="globe" size="medium"></odx-icon>
      </button>
      <button
        [odxTooltip]="newSoftwareVersion ? tooltipNewVersionAvailable : tooltipNoNewVersionAvailable"
        odxButton
        id="reload-button"
        (click)="onClickReload()"
      >
        <odx-icon name="reload" size="medium"></odx-icon>
        <img
          class="reload-overlay"
          src="assets/{{ newSoftwareVersion ? 'warning-colored-odx.svg' : 'checkmark-green.svg' }}"
          alt="{{ newSoftwareVersion ? 'update available' : 'no update available' }}"
        />
      </button>
    </odx-action-group>
    <button odxButton odxHeaderAvatar [odxMenu]="menu" odxMenuPosition="bottom-end">
      <odx-avatar>
        <odx-icon name="menu"></odx-icon>
      </odx-avatar>
    </button>

    <ng-template #localesMenu>
      <app-locales-selection-menu (select)="localesMenuButton.element.nativeElement.click()"></app-locales-selection-menu>
    </ng-template>
    <ng-template #menu>
      <app-main-menu></app-main-menu>
    </ng-template>
  </odx-header>
  <div id="content">
    <router-outlet></router-outlet>
  </div>
</div>
