import { Component } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { AddButtonComponent } from "@components/add-button/add-button.component";
import { ToggleLockButtonComponent } from "@components/toggle-lock-button/toggle-lock-button.component";
import { ToggleVisibilityButtonComponent } from "@components/toggle-visibility-button/toggle-visibility-button.component";
import { FloorplanState } from "@domain/project/floorplan/floorplan-state";
import { FloorplanMenuItemButtonsComponent } from "@project/floorplanner/floorplan-menu/floorplan-menu-item-buttons/floorplan-menu-item-buttons.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";

@Component({
  selector: "app-floorplan-measurement-line-menu",
  templateUrl: "./floorplan-measurement-line-menu.component.html",
  styleUrls: ["./floorplan-measurement-line-menu.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    FloorplanMenuItemButtonsComponent,
    AddButtonComponent,
    ToggleLockButtonComponent,
    ToggleVisibilityButtonComponent,
  ],
})
export class FloorplanMeasurementLineMenuComponent {
  protected floorplanState: FloorplanState;

  constructor(private floorplanService: FloorplanService) {
    this.floorplanState = floorplanService.selectedFloorplan.floorplanState;
  }

  addMeasurementLine() {
    this.floorplanService.addMeasurementLine();
  }
}
