<div odxLayout="flex gap-small" class="flex-column content">
  <div odxLayout="flex" class="space-between header">
    <h5 class="odx-title-5" [odxTooltip]="title" odxTooltipSize="medium">
      {{ title }}
    </h5>
    <button odxButton variant="ghost" (click)="close()">
      <odx-icon name="close"></odx-icon>
    </button>
  </div>

  <div class="image-wrapper" *ngIf="imageUrl">
    <app-standard-image [imageUrl]="imageUrl" [fallbackImageUrl]="fallbackImageUrl"></app-standard-image>
  </div>

  <ng-content></ng-content>

  <form odxForm [formGroup]="notesForm" [readonly]="floorplanItem.locked">
    <odx-form-field i18n-label="@@floorplan.details.textarea.label" label="Notizen">
      <textarea
        formControlName="notes"
        odxFormFieldControl
        i18n-placeholder="@@floorplan.details.textarea.placeholder"
        placeholder="Notizen"
        class="textarea-large"
      ></textarea>
      <ng-template odxFormFieldError>
        <ng-container i18n="@@floorplan.details.textarea.errorMessage.maxLengthExceeded"
          >Maximale Zeichenanzahl überschritten</ng-container
        >
      </ng-template>
    </odx-form-field>
  </form>

  <odx-inline-message *ngIf="showWarning" variant="warning" i18n="@@floorplan.details.warning.discontinued"
    >Enthält abgekündigte Komponenten</odx-inline-message
  >

  <p class="odx-text--small locked-text">
    <odx-icon [name]="floorplanItem.locked ? 'lock' : 'unlock'" inline></odx-icon>
    <span *ngIf="!floorplanItem.locked" i18n="@@floorplan.details.unlockedMessage"
      >Entsperrt am {{ floorplanItem.lockTimestamp | localizeDate }} um {{ floorplanItem.lockTimestamp | localizeTime }}</span
    >
    <span *ngIf="floorplanItem.locked" i18n="@@floorplan.details.lockedMessage"
      >Gesperrt am {{ floorplanItem.lockTimestamp | localizeDate }} um {{ floorplanItem.lockTimestamp | localizeTime }}</span
    >
  </p>
  <div odxLayout="flex" class="single-item-buttons-container">
    <app-delete-button (delete)="delete()" [disabled]="floorplanItem.locked"> </app-delete-button>
    <app-toggle-lock-button [locked]="floorplanItem.locked" (toggle)="floorplanItem.locked = $event"> </app-toggle-lock-button>
    <app-duplicate-button *ngIf="duplicateEnabled" (duplicate)="duplicate()"> </app-duplicate-button>
  </div>
</div>
