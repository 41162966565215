import { FloorplanImage } from "@domain/project/floorplan/floorplan-image";
import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import { PositionIdTextKonva } from "@project/floorplanner/konva/position-id-text-konva";
import Konva from "konva";
import { Group } from "konva/lib/Group";
import { Text } from "konva/lib/shapes/Text";

export class ImageKonva {
  public static TEXT_PADDING = 4;

  static init(floorplanImage: FloorplanImage, img: Konva.Image): Group {
    let imgGroup = new Group({
      id: floorplanImage.id,
      x: floorplanImage.x,
      y: floorplanImage.y,
      stroke: "black",
      strokeWidth: 1,
      draggable: !floorplanImage.locked,
      [FloorplanWorkspaceKonvaAdapter.OBJECT_KEY]: floorplanImage,
    });

    this.initImage(img, floorplanImage, imgGroup);
    const positionId = this.initPositionIdText(floorplanImage, img, imgGroup);
    this.initEventListener(imgGroup, img, positionId, floorplanImage);

    return imgGroup;
  }

  private static initImage(img: Konva.Image, floorplanImage: FloorplanImage, imgGroup: Group) {
    img.setAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY, floorplanImage);
    if (img.getHeight() == 0) {
      img.height(floorplanImage.size);
      img.width(floorplanImage.size);
    }
    const scale = floorplanImage.size / img.getWidth();
    img.scaleX(scale);
    img.scaleY(scale);

    imgGroup.add(img);
  }

  private static initPositionIdText(floorplanImage: FloorplanImage, img: Konva.Image, imgGroup: Group) {
    let positionId = PositionIdTextKonva.init(floorplanImage);
    positionId.padding(this.TEXT_PADDING);
    ImageKonva.setTextPosition(positionId, img, this.TEXT_PADDING);
    imgGroup.add(positionId);
    return positionId;
  }

  static setTextPosition(text: Text, image: Konva.Image, padding = 0) {
    text.x(image.x() + image.scaleX() * image.width());
    text.y(image.y() + image.scaleY() * image.height() + padding - text.height());
  }

  private static initEventListener(imgGroup: Group, img: Konva.Image, positionId: Text, floorplanImage: FloorplanImage) {
    img.on("transform", () => {
      ImageKonva.setTextPosition(positionId, img, this.TEXT_PADDING);
    });
    img.on("dragmove", () => {
      ImageKonva.setTextPosition(positionId, img, this.TEXT_PADDING);
    });
    img.on("transformend", () => {
      floorplanImage.updateSize(img.scaleX() * img.width(), img.x(), img.y());
    });
  }

  static refreshPositionId(floorplanImage: FloorplanImage, group: Group) {
    const image = group.getChildren()[0] as Konva.Image;
    const text = group.getChildren()[1] as Text;
    text.text(floorplanImage.positionId!);
    ImageKonva.setTextPosition(text, image, this.TEXT_PADDING);
  }
}
