<app-floorplan-product-menu
  title="Transmitter"
  i18n-title="@@global.transmitters"
  [hasConfigurations]="floorplan.project.transmitters.length > 0"
  [visible]="floorplan.floorplanState.transmittersVisible"
  [locked]="floorplan.floorplanState.transmittersLocked"
  (visibilityUpdate)="floorplan.floorplanState.transmittersVisible = $event"
  (lockUpdate)="floorplan.floorplanState.transmittersLocked = $event"
  (addPlaceholderToFloorplan)="addPlaceholder()"
  configPath="transmitter"
>
  <button additionalButton odxButton variant="secondary" (click)="toggleMonitoringAreaVisibility()">
    <img
      class="odx-icon"
      [src]="
        floorplan.floorplanState.monitoringAreasVisible
          ? '../../../../assets/ueberwachungsbereich_on_nicht_odx.svg'
          : '../../../../assets/ueberwachungsbereich_off.svg'
      "
    />
  </button>

  <div odxListItem *ngFor="let transmitterSubtitle of transmitterSubtitles | keyvalue">
    <app-configured-product
      [productConfiguration]="transmitterSubtitle.key"
      [numberOfFloorplanItems]="floorplan.countPlacedTransmitters(transmitterSubtitle.key)"
      [attachmentIds]="transmitterSubtitle.key.attachmentIds"
      [subtitlePrefix]="(transmitterSubtitle.value | async)!"
      (add)="addTransmitter(transmitterSubtitle.key)"
      (delete)="deleteTransmitter(transmitterSubtitle.key)"
      editPath="transmitter"
    >
    </app-configured-product>
  </div>
</app-floorplan-product-menu>
