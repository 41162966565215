import { Component, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { CloudProjectsOdxTable } from "@components/cloud-projects-odx-table/cloud-projects-odx-table.component";
import { ConfirmModalComponent } from "@components/confirm-modal/confirm-modal.component";
import { CloudProjectMetadata } from "@domain/project/cloud-project-metadata";
import { ProjectService } from "@domain/project/project.service";
import { ModalService } from "@odx/angular/components/modal";
import { ToastHelper } from "@utils/toast-helper.service";
import { Observable, Subject, map, switchMap } from "rxjs";

@Component({
  templateUrl: "./cloud-project-delete-modal.component.html",
  styleUrls: ["./cloud-project-delete-modal.component.scss"],
  standalone: true,
  imports: [UiKitModule, CloudProjectsOdxTable],
})
export class CloudProjectDeleteModalComponent implements OnInit {
  protected cloudProjects$ = new Subject<CloudProjectMetadata[]>();

  constructor(
    private projectService: ProjectService,
    private modalService: ModalService,
    private toastHelper: ToastHelper,
  ) {}

  ngOnInit() {
    this.loadCloudProjects().subscribe();
  }

  onClickDelete(cloudProject: CloudProjectMetadata) {
    this.projectService.findByCloudId(cloudProject.id).subscribe((project) => {
      if (project) {
        this.toastHelper.error(
          $localize`:@@modal.deleteCloudProjects.error:Das Projekt ${cloudProject.displayName}:projectName: muss zuerst aus "Meine Projekte" entfernt werden, um es aus der Cloud zu löschen.`,
        );
        return;
      }
      this.modalService
        .open(ConfirmModalComponent, {
          data: {
            text: $localize`:@@modal.deleteCloudProjects.askForConfirmation:Möchten Sie dieses Projekt wirklich aus der Cloud löschen? Eine Wiederherstellung ist nicht mehr möglich.`,
          },
        })
        .onClose$.pipe(
          switchMap(() => this.projectService.deleteProjectFromCloud(cloudProject.id)),
          switchMap(() => this.loadCloudProjects()),
        )
        .subscribe(() => {
          this.toastHelper.success(
            $localize`:@@toast.deleteCloudProjectSuccessful:Das Projekt ${cloudProject.displayName}:projectName: wurde erfolgreich aus der Cloud gelöscht.`,
          );
        });
    });
  }

  private loadCloudProjects(): Observable<void> {
    return this.projectService.loadCloudProjectsMetadata().pipe(map((cloudProjects) => this.cloudProjects$.next(cloudProjects)));
  }
}
