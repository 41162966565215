import { Component, inject, Input } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { CloudProjectsOdxTable } from "@components/cloud-projects-odx-table/cloud-projects-odx-table.component";
import { CloudProjectMetadata } from "@domain/project/cloud-project-metadata";
import { ProjectService } from "@domain/project/project.service";
import { ModalRef } from "@odx/angular/components/modal";
import { Observable } from "rxjs";

@Component({
  templateUrl: "./cloud-project-select-modal.component.html",
  styleUrls: ["./cloud-project-select-modal.component.scss"],
  standalone: true,
  imports: [UiKitModule, CloudProjectsOdxTable],
})
export class CloudProjectSelectModalComponent {
  @Input("$implicit")
  private readonly modalRef = inject(ModalRef<any, string>);

  cloudProjects$: Observable<CloudProjectMetadata[]>;
  selectedProject?: CloudProjectMetadata;

  constructor(private projectService: ProjectService) {
    this.cloudProjects$ = projectService.loadCloudProjectsMetadata();
  }

  onConfirm() {
    if (!this.selectedProject) {
      return;
    }
    const cloudId = this.selectedProject.id;
    this.projectService.loadProjectFromCloud(cloudId).subscribe((project) => {
      project.cloudId = cloudId;
      this.modalRef.close(project);
    });
  }
}
