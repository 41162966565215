<odx-bar>
  <button odxBarButton [disabled]="activeStep === 0 || isPreviousButtonDisabled" (click)="previous.emit()">
    <odx-icon size="large" name="arrow-left"></odx-icon>
  </button>
  <label odxBarLabel i18n="@@configurationWizard.navigationStepper.label"> Schritt {{ activeStep + 1 }} von {{ steps }}</label>
  <button odxBarButton variant="small" (click)="close.emit()">
    <odx-icon size="large" name="close"></odx-icon>
  </button>
  <button odxBarButton (click)="next.emit()">
    <odx-icon size="large" name="arrow-right"></odx-icon>
  </button>
</odx-bar>
