export enum PdfProperties {
  POINT_TO_PIXEL_RATIO = 1.333,
  BACKGROUND_COLOR = "#FFFFFF",
  DRAEGERBLUE = "#002766",
  ODX_RED = "#BC0000",
  ODX_YELLOW = "#F57A00",
  ODX_GREY = "#798EA0",
  FOOTER_COLOR = "#D5E2F6",
  HEADLINE_FONT_SIZE = 64 * POINT_TO_PIXEL_RATIO,
  HEADLINE_Y = 104,
  BORDER_WIDTH = 3,
  BORDER_COLOR = "#C7D3E0",
  TABLE_OF_CONTENTS_FONT_SIZE = 25 * PdfProperties.POINT_TO_PIXEL_RATIO,
  TABLE_OF_CONTENTS_FONT_SIZE_LARGE = 36 * PdfProperties.POINT_TO_PIXEL_RATIO,
}

export enum ContainerProperties {
  HEIGHT = 1975,
  WIDTH = 1500,
  LEFT_X = 145,
  RIGHT_X = 1886,
  Y = 255,
  PADDING_Y = 16,
}

export enum FontNames {
  DRAEGER_PANGEA = "DraegerPangea-Medium",
  DRAEGER_PANGEA_TEXT = "DraegerPangeaText-Regular",
  DRAEGER_PANGEA_TEXT_BOLD = "DraegerPangeaText-Bold",
}

export enum TextProperties {
  BASELINE_TOP = "top",
}
export enum ImagePaths {
  LEGEND_DE = "assets/legend_de.png",
  LEGEND_EN = "assets/legend_en.png",
  DRAEGERLOGO = "assets/draeger-logo.png",
  NAVINTALOGO = "assets/navinta-logo.png",
  WATERMARK = "assets/watermark.png",
  GASWARNINGCENTER = "assets/gaswarnanlage.png",
  GASWARNINGCENTER_PLACEHOLDER = "assets/gaswarnanlage_platzhalter_nicht_odx.png",
  TRANSMITTER = "assets/transmitter.png",
  TRANSMITTER_PLACEHOLDER = "assets/transmitter_platzhalter_nicht_odx.png",
  ALARMDEVICE = "assets/alarmmittel_odx.png",
  ALARMDEVICE_PLACEHOLDER = "assets/alarmmittel_platzhalter_nicht_odx.png",
  SIGNALELEMENT = "assets/leuchttransparente.png",
  SIGNALELEMENT_PLACEHOLDER = "assets/leuchttransparente_platzhalter_nicht_odx.png",
  PLASTICSIGN = "assets/kunststoffschild.png",
  PLASTICSIGN_PLACEHOLDER = "assets/kunststoffschild_platzhalter_nicht_odx.png",
  ZONE = "assets/zonen_nicht_odx.png",
  PIPELINE = "assets/rohrleitungen_nicht_odx.png",
  MEASUREMENT_LINE = "assets/bemassungen_nicht_odx.png",
  NOTE = "assets/notizen_odx.png",
  AIR_PATH = "assets/zu-abluftweg_nicht_odx.png",
  IMAGE = "assets/picture_odx.png",
  WARNING_SIGN = "assets/warning-colored-odx.png",
  SWITCH_CHECKED = "assets/odx-switch-checked.png",
  SWITCH_UNCHECKED = "assets/odx-switch-unchecked.png",
}

export enum ImageTypes {
  PNG = "png",
}

export class Headlines {
  public readonly FLOORPLAN = $localize`:@@pdfExport.headline.floorplan:Grundrissplan`;
  public readonly LEGEND = $localize`:@@pdfExport.headline.legend:Legende`;
  public readonly PRODUCT_LIST = $localize`:@@pdfExport.headline.productList:Produktliste`;
  public readonly PRODUCT_NOTES = $localize`:@@pdfExport.headline.productNotes:Produktnotizen`;
  public readonly FLOORPLAN_INFORMATION = $localize`:@@pdfExport.headline.floorplanInformation:Grundrissplan Informationen`;
  public readonly GASWARNINGCENTERS = $localize`:@@pdfExport.headline.gasWarningCenters:Gaswarnzentralen`;
  public readonly TRANSMITTERS = $localize`:@@pdfExport.headline.transmitters:Transmitter`;
  public readonly ALARMDEVICES = $localize`:@@pdfExport.headline.alarmDevices:Alarmmittel`;
  public readonly SIGNALELEMENTS = $localize`:@@pdfExport.headline.signalElements:Leuchttransparente`;
  public readonly PLASTICSIGNS = $localize`:@@pdfExport.headline.plasticSigns:Kunststoffschilder`;
  public readonly ZONES = $localize`:@@pdfExport.headline.zones:Zonen`;
  public readonly PIPELINES = $localize`:@@pdfExport.headline.pipelines:Rohrleitungen`;
  public readonly MEASUREMENT_LINES = $localize`:@@pdfExport.headline.measurements:Bemaßungen`;
  public readonly NOTES = $localize`:@@pdfExport.headline.notes:Notizen`;
  public readonly AIR_PATHS = $localize`:@@pdfExport.headline.airPaths:Zu-/Abluftwege`;
  public readonly IMAGES = $localize`:@@pdfExport.headline.images:Fotos`;
  public readonly GASWARNINGCENTERS_PLACEHOLDERS = $localize`:@@pdfExport.headline.gasWarningCenterPlaceholders:Gaswarnzentralen (Platzhalter)`;
  public readonly TRANSMITTERS_PLACEHOLDERS = $localize`:@@pdfExport.headline.transmitterPlaceholders:Transmitter (Platzhalter)`;
  public readonly ALARMDEVICES_PLACEHOLDERS = $localize`:@@pdfExport.headline.alarmDevicePlaceholders:Alarmmittel (Platzhalter)`;
  public readonly SIGNALELEMENTS_PLACEHOLDERS = $localize`:@@pdfExport.headline.signalElementPlaceholders:Leuchttransparente (Platzhalter)`;
  public readonly PLASTICSIGNS_PLACEHOLDERS = $localize`:@@pdfExport.headline.plasticSignPlaceholders:Kunststoffschilder (Platzhalter)`;
  public readonly SERVICES = $localize`:@@pdfExport.headline.services:Dienstleistungen`;
  public readonly CHECKLIST = $localize`:@@pdfExport.headline.checklist:Checkliste`;
  public readonly TABLE_OF_CONTENTS = $localize`:@@pdfExport.headline.tableOfContents:Inhaltsverzeichnis`;
}

export enum TableProperties {
  EMPTY_TABLE_HEIGHT = 152,
  GAP_BETWEEN_TABLES = 84,
  HEADLINE_OFFSET_Y = 30,
  HEADLINE_ICON_WIDTH = 24,
  HEADLINE_ICON_HEIGHT = 24,
  WARNING_ICON_SIZE = 20,
  GAP_HEADLINE_HEADERS = 82,
  PADDING_X = 48,
  PADDING_12 = 12,
  PADDING_26 = 26,
  PADDING_32 = 32,
  PADDING_64 = 64,
  ROW_PADDING_TOP = 14,
  ROW_PADDING_BOTTOM = 8,
  ROW_PADDING_Y = ROW_PADDING_BOTTOM + ROW_PADDING_TOP,
  ROW_PADDING_X = 16,
  ROW_SEPARATOR_LENGTH = 1404,
  SEPARATOR_COLOR = "#DDE5EE",
  SEPARATOR_COLOR_STRONG = "#5D6A74",
  SEPARATOR_WIDTH = 1,
  HEADER_FONT_SIZE = 25 * PdfProperties.POINT_TO_PIXEL_RATIO,
  HEADER_HEIGHT = 96,
  TEXT_FONT_SIZE = 20 * PdfProperties.POINT_TO_PIXEL_RATIO,
  TEXT_HEIGHT = 26.65,
}

export enum ProductListProperties {
  SUMMARY_FONT_SIZE = 25 * PdfProperties.POINT_TO_PIXEL_RATIO,
  FULL_SUMMARY_HEIGHT = 192,
  SUMMARY_HEIGHT = 96,
  FOOTER_HEIGHT = 126,
}
