<div odxLayout="flex" class="flex-column container" *ngIf="user$ | async as user">
  <app-country-selection
    [selectedCountry]="selectedCountry || user.country"
    (select)="onClickSelectCountry($event)"
  ></app-country-selection>
  <app-language-selection
    [selectedLanguage]="selectedLanguage || user.language"
    (select)="onClickSelectLanguage($event)"
  ></app-language-selection>
  <button odxButton variant="primary" (click)="onSubmit()" i18n="@@global.save" data-testid="btn.localesMenu.submit">
    Speichern
  </button>
</div>
