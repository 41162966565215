import { CommonModule, CurrencyPipe } from "@angular/common";
import { Component, Input } from "@angular/core";

import { ProductData } from "@domain/project/product-data/product-data";

@Component({
  selector: "app-product-table",
  templateUrl: "./product-table.component.html",
  styleUrls: ["./product-table.component.scss"],
  standalone: true,
  imports: [CurrencyPipe, CommonModule],
})
export class ProductTableComponent {
  @Input() showCosts!: boolean;
  @Input() productData: ProductData[][] = [];
  @Input() placeholderData: ProductData[][] = [];
}
