import { DefaultRow } from "@pdf/helpers/default-row/default-row";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";

export class ProductNotesRow extends DefaultRow {
  readonly position: string;
  readonly notes: string[];
  readonly name?: string[];
  readonly text?: string[];
  readonly textColor?: string[];
  readonly backgroundColor?: string[];

  constructor(
    pdf: jsPDF,
    columns: TableColumn[],
    position: string,
    notes: string,
    name?: string,
    text?: string,
    textColor?: string,
    backgroundColor?: string,
  ) {
    super();
    this.position = position;
    this.notes = notes.length ? this.splitText(pdf, columns[columns.length - 1].width, notes) : ["--"];
    this.name = name ? this.splitText(pdf, columns[1].width, name) : undefined;
    this.text = text ? this.splitText(pdf, columns[2].width, text) : undefined;
    this.textColor = textColor ? this.splitText(pdf, columns[3].width, textColor) : undefined;
    this.backgroundColor = backgroundColor ? this.splitText(pdf, columns[4].width, backgroundColor) : undefined;
    this._textHeight = this.calculateRowTextHeight(pdf);
    this._rowHeight = this.calculateRowHeight(this.textHeight);
  }

  private splitText(pdf: jsPDF, width: number, text: string): string[] {
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    return pdf.splitTextToSize(text, width);
  }

  private calculateRowTextHeight(pdf: jsPDF) {
    let cellHeights: number[] = [];
    if (this.name) {
      cellHeights.push(this.calculateCellTextHeight(pdf, this.name));
    }
    if (this.text) {
      cellHeights.push(this.calculateCellTextHeight(pdf, this.text));
    }
    if (this.textColor) {
      cellHeights.push(this.calculateCellTextHeight(pdf, this.textColor));
    }
    if (this.backgroundColor) {
      cellHeights.push(this.calculateCellTextHeight(pdf, this.backgroundColor));
    }
    cellHeights.push(this.calculateCellTextHeight(pdf, this.notes));
    return Math.max(...cellHeights);
  }
}
